import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: -1
}

const actions = {
    // Document
    getDocument({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v3/document?'

        // Filters
        if (data.filter) {
            if (data.filter.docId) url += '&doc_id='+ data.filter.docId
            if (data.filter.takahId) url += '&takah_id='+ data.filter.takahId
            if (data.filter.docNo) url += '&doc_no='+ data.filter.docNo
            if (data.filter.title) url += '&title='+ data.filter.title
            if (data.filter.type && data.filter.type != "Semua") url += '&type='+ data.filter.type
            if (data.filter.category && data.filter.category != "Semua") url += '&category_name='+ data.filter.category
            if (data.filter.trait && data.filter.trait != "Semua") url += '&trait='+ data.filter.trait
            if (data.filter.dateStart) url += '&start_date='+ data.filter.dateStart
            if (data.filter.dateFinish) url += '&finish_date='+ data.filter.dateFinish
            if (data.filter.isFavorite) url += '&is_favorite='+ data.filter.isFavorite
            if (data.filter.isPublish) url += '&is_publish='+ data.filter.isPublish
            if (data.filter.isArchive) url += '&is_archive='+ data.filter.isArchive
            if (data.filter.isDone != null) url += '&is_done='+ data.filter.isDone
            if (data.filter.uploadBy) url += '&upload_by='+ data.filter.uploadBy
            if (data.filter.badge && data.filter.badge != "Semua") url += '&badge='+ data.filter.badge
            if (data.filter.level && data.filter.level != "Semua") url += '&level='+ data.filter.level
            if (data.filter.search && data.filter.search != '') url += '&search='+ data.filter.search
            if (data.filter.isPrint) url += '&is_print=' + data.filter.isPrint
            if (data.filter.isDeleted) url += '&delete=' + data.filter.isDeleted
        }
        if (data.marks) {
            url += '&marks='+ JSON.stringify(data.marks)
        }
        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        if (data.sort) {
            if (data.sort.orderBy) url += '&order_by='+ data.sort.orderBy
            if (data.sort.sort) url += '&sort='+ data.sort.sort
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    getDocumentV2({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document?'

        // Filters
        if (data.filter) {
            if (data.filter.docId) url += '&doc_id='+ data.filter.docId
            if (data.filter.takahId) url += '&takah_id='+ data.filter.takahId
            if (data.filter.docNo) url += '&doc_no='+ data.filter.docNo
            if (data.filter.title) url += '&title='+ data.filter.title
            if (data.filter.type && data.filter.type != "Semua") url += '&type='+ data.filter.type
            if (data.filter.category && data.filter.category != "Semua") url += '&category_name='+ data.filter.category
            if (data.filter.trait && data.filter.trait != "Semua") url += '&trait='+ data.filter.trait
            if (data.filter.dateStart) url += '&start_date='+ data.filter.dateStart
            if (data.filter.dateFinish) url += '&finish_date='+ data.filter.dateFinish
            if (data.filter.isFavorite) url += '&is_favorite='+ data.filter.isFavorite
            if (data.filter.isPublish) url += '&is_publish='+ data.filter.isPublish
            if (data.filter.isArchive) url += '&is_archive='+ data.filter.isArchive
            if (data.filter.isDone != null) url += '&is_done='+ data.filter.isDone
            if (data.filter.uploadBy) url += '&upload_by='+ data.filter.uploadBy
            if (data.filter.badge && data.filter.badge != "Semua") url += '&badge='+ data.filter.badge
            if (data.filter.level && data.filter.level != "Semua") url += '&level='+ data.filter.level
            if (data.filter.search && data.filter.search != '') url += '&search='+ data.filter.search
            if (data.filter.isPrint) url += '&is_print=' + data.filter.isPrint
        }
        if (data.marks) {
            url += '&marks='+ JSON.stringify(data.marks)
        }
        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        if (data.sort) {
            if (data.sort.orderBy) url += '&order_by='+ data.sort.orderBy
            if (data.sort.sort) url += '&sort='+ data.sort.sort
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    getDetailDocument({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document?'

        // Filters
        if (data.filter) {
            if (data.filter.docId) url += '&doc_id='+ data.filter.docId
            if (data.filter.takahId) url += '&takah_id='+ data.filter.takahId
            if (data.filter.docNo) url += '&doc_no='+ data.filter.docNo
            if (data.filter.title) url += '&doc_name='+ data.filter.title
            if (data.filter.type && data.filter.type != "Semua") url += '&type='+ data.filter.type
            if (data.filter.category && data.filter.category != "Semua") url += '&category_name='+ data.filter.category
            if (data.filter.trait && data.filter.trait != "Semua") url += '&trait='+ data.filter.trait
            if (data.filter.dateStart) url += '&start_date='+ data.filter.dateStart
            if (data.filter.dateFinish) url += '&finish_date='+ data.filter.dateFinish
            if (data.filter.isFavorite) url += '&is_favorite='+ data.filter.isFavorite
            if (data.filter.isPublish) url += '&is_publish='+ data.filter.isPublish
            if (data.filter.isArchive) url += '&is_archive='+ data.filter.isArchive
            if (data.filter.isDone != null) url += '&is_done='+ data.filter.isDone
            if (data.filter.uploadBy) url += '&upload_by='+ data.filter.uploadBy
            if (data.filter.badge && data.filter.badge != "Semua") url += '&badge='+ data.filter.badge
            if (data.filter.level && data.filter.level != "Semua") url += '&level='+ data.filter.level
            if (data.filter.search && data.filter.search != '') url += '&search='+ data.filter.search
            if (data.filter.isPrint) url += '&is_print=' + data.filter.isPrint
            if (data.filter.isForceAccess) url += '&force_access=' + data.filter.isForceAccess
        }
        if (data.marks) {
            url += '&marks='+ JSON.stringify(data.marks)
        }
        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        if (data.sort) {
            if (data.sort.orderBy) url += '&order_by='+ data.sort.orderBy
            if (data.sort.sort) url += '&sort='+ data.sort.sort
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    storeDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.docFileId && params.formInput.docFileId != '') form.append('doc_file_id', params.formInput.docFileId)
        if (params.formInput.categoryId && params.formInput.categoryId != '') form.append('category_id', params.formInput.categoryId)
        if (params.formInput.docNumber && params.formInput.docNumber != '') form.append('doc_no', params.formInput.docNumber)
        if (params.formInput.title && params.formInput.title != '') form.append('title', params.formInput.title)
        if (params.formInput.isWorkflow && params.formInput.isWorkflow != '') form.append('is_workflow', params.formInput.isWorkflow)
        if (params.formInput.workflow && params.formInput.workflow != '') form.append('workflow', params.formInput.workflow)
        if (params.formInput.approval && params.formInput.approval != '') form.append('approval', params.formInput.approval)
        if (params.formInput.tags && params.formInput.tags.length > 0) form.append('tags', params.formInput.tags)
        if (params.formInput.attachments && params.formInput.attachments.length > 0) form.append('attachments', params.formInput.attachments)
        if (params.formInput.attachmentsEDoc && params.formInput.attachmentsEDoc.length > 0) form.append('attachments_e_doc', params.formInput.attachmentsEDoc)
        if (params.formInput.revisionId && params.formInput.revisionId != '') form.append('id_revision', params.formInput.revisionId)
        if (params.formInput.createdAt && params.formInput.createdAt != '') form.append('created_at', params.formInput.createdAt)
        if (params.formInput.takah && params.formInput.takah != '') form.append('takah_id', params.formInput.takah.id)
        if (params.formInput.description && params.formInput.description != '') form.append('description', params.formInput.description)
        if (params.formInput.isReference) form.append('is_reference', params.formInput.isReference)
        // Disposition Verification
        if (params.formInput.isVerification && params.formInput.isVerification != '') form.append('is_verification', params.formInput.isVerification)
        if (params.formInput.verification && params.formInput.verification != '') form.append('verification', params.formInput.verification)
        
        if(params.formInput.readySend) form.append('ready_send', params.formInput.readySend)
        if (params.formInput.publishDate && params.formInput.publishDate != '') form.append('publish_date', params.formInput.publishDate)
       
        let doc_marks = {}
        if (params.formInput.type && params.formInput.type != '') doc_marks['type'] = params.formInput.type
        if (params.formInput.from && params.formInput.from != '') doc_marks['from'] = params.formInput.from
        if (params.formInput.trait && params.formInput.trait != '') doc_marks['trait'] = params.formInput.trait
        if (params.formInput.sender && params.formInput.sender != '') doc_marks['sender'] = params.formInput.sender
        if (params.formInput.to && params.formInput.to != '') doc_marks['to'] = params.formInput.to
        if (params.formInput.agendaNumber && params.formInput.agendaNumber != '') doc_marks['agenda_no'] = params.formInput.agendaNumber
        if (params.formInput.level && params.formInput.level != '') doc_marks['level'] = params.formInput.level

        if (JSON.stringify(doc_marks)) form.append('marks', JSON.stringify(doc_marks))

        // receiver
        if (params.formInput.userReceivers && params.formInput.userReceivers.length > 0) form.append('receiver_user_id', params.formInput.userReceivers)
        
        return (!token)? false : apiBase
            .post(
                'v3/document',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    updateDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.categoryId && params.formInput.categoryId != '') form.append('category_id', params.formInput.categoryId)
        if (params.formInput.docNumber && params.formInput.docNumber != '') form.append('doc_no', params.formInput.docNumber)
        if (params.formInput.title && params.formInput.title != '') form.append('title', params.formInput.title)
        if (params.formInput.isWorkflow && params.formInput.isWorkflow != '') form.append('is_workflow', params.formInput.isWorkflow)
        if (params.formInput.workflow && params.formInput.workflow != '') form.append('workflow', params.formInput.workflow)
        if (params.formInput.approval && params.formInput.approval != '') form.append('approval', params.formInput.approval)
        if (params.formInput.tags && params.formInput.tags.length > 0) form.append('tags', params.formInput.tags)
        if (params.formInput.attachments && params.formInput.attachments.length > 0) form.append('attachments', params.formInput.attachments)
        if (params.formInput.takah && params.formInput.takah != '') form.append('takah_id', params.formInput.takah.id)
        if (params.formInput.description && params.formInput.description != '') form.append('description', params.formInput.description)
        // if (params.formInput.createdAt && params.formInput.createdAt != '') form.append('created_at', params.formInput.createdAt)

        let doc_marks = {}
        if (params.formInput.type && params.formInput.type != '') doc_marks['type'] = params.formInput.type
        if (params.formInput.from && params.formInput.from != '') doc_marks['from'] = params.formInput.from
        if (params.formInput.trait && params.formInput.trait != '') doc_marks['trait'] = params.formInput.trait
        if (params.formInput.sender && params.formInput.sender != '') doc_marks['sender'] = params.formInput.sender
        if (params.formInput.to && params.formInput.to != '') doc_marks['to'] = params.formInput.to
        if (params.formInput.agendaNumber && params.formInput.agendaNumber != '') doc_marks['agenda_no'] = params.formInput.agendaNumber
        if (params.formInput.level && params.formInput.level != '') doc_marks['level'] = params.formInput.level

        if (JSON.stringify(doc_marks)) form.append('marks', JSON.stringify(doc_marks))
        
        return (!token)? false : apiBase
            .post(
                'v3/document/'+ params.formInput.docId,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    destroyDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.type && params.formInput.type != '') form.append('type', params.formInput.type)
        
        return (!token)? false : apiBase
            .delete(
                'v1/document/'+ params.formInput.docId, 
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    getDocumentActivity({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/activity?'

        // Filters
        if (data.filter) {
            if (data.filter.doc_id) url += '&doc_id='+ data.filter.doc_id
            if (data.filter.creating) url += '&creating='+ data.filter.creating
            if (data.filter.mark) url += '&mark='+ data.filter.mark
            if (data.filter.approval) url += '&approval='+ data.filter.approval
            if (data.filter.approval_changed) url += '&approval_changed='+ data.filter.approval_changed
            if (data.filter.tag) url += '&tag='+ data.filter.tag
            if (data.filter.comment) url += '&comment='+ data.filter.comment
            if (data.filter.disposition_create) url += '&disposition_create='+ data.filter.disposition_create
            if (data.filter.disposition_send) url += '&disposition_send='+ data.filter.disposition_send
            if (data.filter.disposition_receive) url += '&disposition_receive='+ data.filter.disposition_receive
        }

        if (data.pagination) {
            if(data.pagination.limit) url += '&limit='+data.pagination.limit
            if(data.pagination.offset) url += '&offset='+data.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },
    getDocumentComments({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/comment?'

        // Filters
        if(data.filter){
            if(data.filter.doc_id) url += '&doc_id='+data.filter.doc_id
            if(data.filter.isDispo) url += '&is_dispo='+data.filter.isDispo
        }

        if(data.pagination){
            if(data.pagination.limit) url += '&limit='+data.pagination.limit
            if(data.pagination.offset) url += '&offset='+data.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },
    getDocumentVersions({commit,payload}, params){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/doc_all_version/'+ params.filter.doc_id

        return (!token)? false : apiBase
            .get(
                url, { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },
    postComment({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.doc_id) form.append('doc_id', data.formInput.doc_id)
        if(data.formInput.message) form.append('message', data.formInput.message)
        
        return (!token)? false : apiBase
            .post(
                'v1/document/comment',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    updateComment({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/comment/' + data.formInput.comment_id

        // Filters
        let form = new FormData()
        if(data.formInput.message) form.append('message', data.formInput.message)
        
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    setFavorite({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/favorite/'+ params.doc_id

        // filters
        let form = new FormData()
        if (params.doc_id) {
            form.append('favorite', params.favorite)
        }
        
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then(res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },
    updateDocNumber({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/'+ params.formInput.docId

        // Filters
        let form = new FormData()
        if (params.formInput.docNumber && params.formInput.docNumber != '') form.append('doc_no', params.formInput.docNumber)
        if (params.formInput.publishDate && params.formInput.publishDate != '') form.append('publish_date', params.formInput.publishDate)
        if (params.formInput.isDone && params.formInput.isDone != null) form.append('is_done', params.formInput.isDone)
        if (params.formInput.isRead && params.formInput.isRead != null) form.append('is_read', params.formInput.isRead)

        return (!token)? false : apiBase
            .put(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    updateFlagRead({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/'+ params.formInput.docId

        // Filters
        let form = new FormData()
        if (params.formInput.isRead) form.append('is_read', params.formInput.isRead)
        
        return (!token)? false : apiBase
            .put(
                url, 
                form,
                { headers }
            ).then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            })
    },

    // Attachment
    getAttachmentFile({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/attachment/'+ params.filter.fileId +'?'

        // Filters
        if (params.filter.outputType) url += 'type='+ params.filter.outputType

        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: 'blob'
                }
            ).then( res => {
                let result = null
                var filename = ""
                var disposition = res.headers["content-disposition"]
                if (disposition) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    var matches = filenameRegex.exec(disposition)
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g,'')
                    }
                }

                result = {
                    filename: filename,
                    file: res.data
                }
                
                return (res.status.toString().startsWith('20'))? result : null
            })
    },
    uploadDocumentV2({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
        }

        let form = new FormData();
        if (params.formInput.filename && params.formInput.filename != '') form.append('filename', params.formInput.filename)
        if (params.formInput.file && params.formInput.file.length > 0) form.append('file', params.formInput.file[0])
        
        return (!token)? false : apiBase
            .post(
                'v2/document/attachment',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    uploadDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.filename && params.formInput.filename != '') form.append('filename', params.formInput.filename)
        if (params.formInput.file && params.formInput.file.length > 0) form.append('file', params.formInput.file[0])
        
        return (!token)? false : apiBase
            .post(
                'v1/document/attachment',
                form,
                { 
                    headers,
                    onUploadProgress: uploadEvent => {
                        let progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
                        setTimeout( e => { commit('PROGRESS', progress) }, 500)
                    }
                }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return null
            }).finally( () => {
                commit('PROGRESS', -1)
            })
    },
    getAttachmentBase64({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/attachment/base64?doc_file_id=' + params.formInput.fileId

        // Filters
        if (params.formInput.outputType && params.formInput.outpuType != '') url += '&type='+ params.formInput.outputType

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    uploadDocumentBase64({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if (params.formInput.filename && params.formInput.filename != '') form.append('filename', params.formInput.filename)
        if (params.formInput.fileBase64 && params.formInput.fileBase64.length > 0) form.append('file_base64', params.formInput.fileBase64)
        if (params.formInput.pdfBase64 && params.formInput.pdfBase64 != '') form.append('pdf_base64', params.formInput.pdfBase64)
        
        return (!token)? false : apiBase
            .post(
                'v1/document/attachment/base64',
                form,
                { 
                    headers,
                    onUploadProgress: uploadEvent => {
                        let progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
                        setTimeout( e => { commit('PROGRESS', progress) }, 500)
                    }
                }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            }).finally( () => {
                commit('PROGRESS', -1)
            })
    },

    // Document Email
    getEmailDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/email_document?'

        // Filters
        if (params.filter) {
            if (params.filter.docFileId && params.filter.docFileId.length > 0) url += '&doc_file_id='+ params.filter.docFileId
            if (params.filter.type && params.filter.type != '') url += '&type='+ params.filter.type
        }

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    fetchMailbox({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/email_document/'+ params.formInput.docFileId+'?'

        // Filters
        if (params.formInput) {
            if (params.formInput.outputType) url += 'type='+ params.formInput.outputType
        }
        
        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: 'blob'
                }
            ).then( res => {
                let result = null
                if (params.formInput.outputType == 'file') {
                    var filename = ""
                    var disposition = res.headers["content-disposition"]
                    if (disposition) {
                        var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                        var matches = filenameRegex.exec(disposition)
                        if (matches != null && matches[1]) { 
                            filename = matches[1].replace(/['"]/g, '')
                        }
                    }

                    result = {
                        filename: filename,
                        file: res.data
                    }
                } else {
                    result = res.data
                }

                return (res.status.toString().startsWith('20')) ? result : false
            }).catch( err => {
                console.log(err.response)
                return false
            })
    },

    // Document Categories
    getCategory({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/category?'

        // Filters
        if (params.filter) {
            if (params.filter.categoryId) url += '&category_id='+ params.filter.categoryId
            if (params.filter.categoryName) url += '&category_name='+ params.filter.categoryName
        }

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20'))? res.data : null
            }).catch( err => {
                return err
            })
    },
    fetchCategory({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/category/'+ params.formInput.categoryId
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20'))? res.data : null
            }).catch( err => {
                return err
            })
    },
    createCategory({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if (params.formInput.categoryName && params.formInput.categoryName != '') form.append('category_name', params.formInput.categoryName)
        if (params.formInput.workflows && params.formInput.workflows.length > 0) form.append('workflow_id_list', params.formInput.workflows)
        
        return (!token)? false : apiBase
            .post(
                'v1/document/category',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return err
            })
    },
    updateCategory({commit,payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if (params.formInput.choice && params.formInput.choice != '') form.append('choice', params.formInput.choice)
        if (params.formInput.categoryName && params.formInput.categoryName != '') form.append('category_name', params.formInput.categoryName)
        if (params.formInput.newCategoryName && params.formInput.newCategoryName != '') form.append('new_name', params.formInput.newCategoryName)
        if (params.formInput.workflows && params.formInput.workflows.length > 0) form.append('workflow_id_list', params.formInput.workflows)

        return (!token)? false : apiBase
            .put(
                'v1/document/category/'+ params.formInput.categoryId,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    destroyCategory({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        return (!token)? false : apiBase
            .delete(
                'v1/document/category/'+ params.formInput.categoryId, 
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },

    // Document Templates
    getTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template?'

        // Filters
        if (params.filter) {
            if (params.filter.templateName) url += '&template_name='+ params.filter.templateName
            if (params.filter.categoryId) url += '&category_id='+ params.filter.categoryId
        }

        if (params.pagination) {
            if(params.pagination.limit) url += '&limit='+ params.pagination.limit
            if(params.pagination.offset) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    fetchTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template/'+ params.filter.templateId +'?'

        // Filters
        if (params.filter.outputType) url += 'output_type='+ params.filter.outputType
        
        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: (params.filter.outputType == 'preview')? 'blob' : 'json'
                }
            ).then( res => {
                return (res.status.toString().startsWith('20'))? res.data : null
            }).catch( err => {
                return err
            })
    },
    createTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if (params.formInput.templateCategory && params.formInput.templateCategory != '') form.append('template_category', params.formInput.templateCategory)
        if (params.formInput.templateName && params.formInput.templateName != '') form.append('template_name', params.formInput.templateName)
        if (params.formInput.templateFile && params.formInput.templateFile.length > 0) form.append('template_file', params.formInput.templateFile[0])
        
        return (!token)? false : apiBase
            .post(
                'v1/document/template',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20'))? res.data : false
            }).catch( err => {
                return false
            })
    },
    destroyTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template/'+ params.formInput.templateId
        
        return (!token)? false : apiBase
            .delete(
                url, 
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },

    // My Document (Dycnamic Input)
    getMyDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/dynamic_input?'

        // Filters
        if (params.filter) {
            if (params.filter.filename) url += '&template_name='+ params.filter.filename
        }

        if (params.pagination) {
            if(params.pagination.limit) url += '&limit='+ params.pagination.limit
            if(params.pagination.offset) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status == 200 || res.status == 204) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    fetchMyDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/dynamic_input/'+ params.formInput.inputId+'?'

        // Filters
        if(params.formInput.outputType) url += 'output_type='+params.formInput.outputType
        
        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: (
                        params.formInput.outputType == 'preview' || 
                        params.formInput.outputType == 'thumbnail'
                        )?'blob':'json'
                }
            ).then( res => {
                return (res.status == 200 || res.status == 204) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    createMyDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/dynamic_input/'+ params.formInput.templateId
        
        // Filters
        let form = new FormData()
        if (params.formInput.filename && params.formInput.filename != '') form.append('filename', params.formInput.filename)
        if (params.formInput.dynamicField && params.formInput.dynamicField.length > 0) {
            let input_fields = []
            params.formInput.dynamicField.forEach((obj) => {
                input_fields.push(obj.model)
            })
            form.append('input_fields', input_fields)
        }

        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },
    destroyMyDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/dynamic_input/'+ params.formInput.inputId+'?'
        url += 'option=input record'
        
        return (!token)? false : apiBase
            .delete(
                url, 
                { headers }
            ).then( res => {
                return (res.status == 200 || res.status == 204) ? true : false
            }).catch( err => {
                return false
            })
    },

    // Request to Admin
    sendRequest({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if (params.formInput.doc_id && params.formInput.doc_id != '') form.append('doc_id', params.formInput.doc_id)
        if (params.formInput.type && params.formInput.type != '') form.append('type', params.formInput.type)
        if (params.formInput.request_note && params.formInput.request_note != '') form.append('request_note', params.formInput.request_note)

        let url = 'v1/monitor/admin_request'
        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return err
            })
    },

    // Summary Data
    getSummary({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/count?'

        // Filters
        if (params.filter) {
            if (params.filter.isPublish) url += '&is_publish='+ params.filter.isPublish
        }

        return (!token)? false : apiBase
            .get(
                url,
                {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },
    getDocSummary({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/count_on_process'

        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    
    getDocumentCountOnProcess({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/count_on_process'

        return !(token)? false : apiBase.get(url, {headers}).then( res => {
            return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },


    getDocSummaryManual({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document?'

        // Filters
        if (params.filter) {
            if (params.filter.type) url += 'type='+ params.filter.type
            if (params.filter.isArchive) url += '&is_archive='+ params.filter.isArchive
            if (params.filter.isPublish) url += '&is_publish='+ params.filter.isPublish
            if (params.filter.isFavorite) url += '&is_favorite='+ params.filter.isFavorite
            if (params.filter.isDone != null) url += '&is_done='+ params.filter.isDone
        }

        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data.total_count : 0
            })
    },

    // Share
    getShareUserException({commit,payload}, params){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/share_except_user?doc_id='+ params.filter.docId

        return (!token)? false : apiBase
            .get(url, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    share({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document/share'

        // Filters
        let form = new FormData()
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        // Hide temp
        if(params.formInput.isEksternalShare) {
            if (params.formInput.sharedSatker && params.formInput.sharedSatker.length > 0) form.append('shared_satker', params.formInput.sharedSatker)
        } else {
            if (params.formInput.shareUserIds && params.formInput.shareUserIds.length > 0) form.append('share_user_id', params.formInput.shareUserIds)
        }

        if (params.formInput.receiverNotes && params.formInput.receiverNotes.length > 0) form.append('comment', params.formInput.receiverNotes)
        
        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },
    getMonitoringShare({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/monitor/shared?doc_id='+ params.filter.docId

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            })
    },


    // Disposition Verification
    verify({commit,payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        const url = 'v1/document/verification/'+ params.formInput.verificationId

        // Filter
        let form = new FormData()
        form.append('verified', params.formInput.verified)

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    // Rollback Approval & Force Publish
    action({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/monitor/workflow/' + params.formInput.docId

        // Filters
        let form = new FormData()
        form.append('action', (params.formInput.action && params.formInput.action != '') ? params.formInput.action : 'rollback')
        form.append('rollback_queue', (params.formInput.queue && params.formInput.queue != '') ? params.formInput.queue : 0)

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    // Unpublish
    unpublishDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/publish_rollback/' + params.formInput.docFileId

        // Filters
        let form = new FormData()
        form.append('message', (params.formInput.message && params.formInput.message != '') ? params.formInput.message : '')

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    getAgenda({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/agenda?doc_type=' + params.filter.type
        if(params.filter.dateStart) {
            url = url + "&start_date="+ params.filter.dateStart
        }
        if(params.filter.dateFinish) {
            url = url + "&finish_date="+ params.filter.dateFinish
        }
        if(params.filter.search) {
            url = url + "&search="+ params.filter.search
        }

        if(params.pagination.limit) {
            url = url + "&limit=" + params.pagination.limit
        }

        if(params.pagination.offset) {
            url = url + "&offset=" + params.pagination.offset
        }

        url = decodeURI(url)
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
        })
    },

    isExist({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let url = 'v1/document/is_exist?doc_no=' + params.filter.docNo
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
        })
    },

    agendaNoIsExist({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let url = 'v1/document/is_exist?agenda_no=' + params.filter.agendaNo
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
        })
    },

    createDocumentByTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/document/input_template/'+params.formInput.templateId
        url = url +"?is_draft="+ params.formInput.isDraft

        
        let form = new FormData()
        var keyData = []
        var valueData = []

        Object.entries(params.formInput.dynamicField).forEach(([key, value])=> {
            keyData.push(value.label)
            valueData.push(value.model)
        })
        

        form.append('key', keyData)
        form.append('value', valueData)

        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    getMonitoringDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let url = 'v1/document/monitoring?'

        
        if(params.filter) {
            let filter = params.filter

            url += '&type=' + filter.type

            if(filter.dateStart) url += '&start_date' + filter.dateStart
            if(filter.dateFinish) url += '&finish_date' + filter.dateFinish

            if(filter.title != "") url += '&title=' + filter.title
            if(filter.docNo != "") url += '&doc_no=' + filter.docNo
            if(filter.selectedStatus) url += '&status_document=' + filter.selectedStatus
            if(filter.selectedSortBy) {
                url += '&order_by=' + filter.selectedSortBy.order_by
                url += '&sort=' + filter.selectedSortBy.sortBy
            }
        }

        url += '&limit=100000&offset=0'
       
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
        })
    },

    getVerificationMonitoring({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let url = 'v1/document/verification/monitoring/' + params.filter.docId
       
        
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
        })
    },

    getApprovalMonitoring({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document/approval/monitoring/'+ params.filter.docId

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getDocumentAssociate({ commit, payload }, params) {
        let token = localStorage.getItem("token")
        let headers = {
        Authorization: "Bearer " + token,
        "Content-Type": "application/x-www-form-urlencoded",
        }
        let url = "v1/document/associate/" + params.docId

        return !token
        ? false
        : apiBase.get(url, { headers }).then((res) => {
            return res.status.toString().startsWith("20") ? res.data : false;
            })
    },

    
    getZipDocument({commit, payload}, params) {
        let token = params.token
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/attachment/free/'+ params.docId


        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: 'blob'
                }
            ).then( res => {
                let result = null
                var filename = ""
                var disposition = res.headers["content-disposition"]
                if (disposition) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    var matches = filenameRegex.exec(disposition)
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g,'')
                    }
                }

                result = {
                    filename: filename,
                    file: res.data
                }
                
                return (res.status.toString().startsWith('20'))? result : null
            })
    },

    getApprovalSubmission({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/document/approval/submission?user_id=' + data.userId
        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    
    getLastNoAgenda({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/last_no_agenda'

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
