import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    // Memo
    getMemo({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/memo?'

        // Filters
        if (params.filter)
            if (params.filter.memoId) url += '&memo_id='+params.filter.memoId

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },
    storeMemo({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.content !== undefined) form.append('content', params.formInput.content)
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        
        return (!token)? false : apiBase
            .post(
                'v1/disposition/memo',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    // Disposition
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/disposition?'
        if (params.filter) {
            if (params.filter.type) url += '&type='+ params.filter.type
            if (params.filter.memoId) url += '&memo_id='+ params.filter.memoId
            if (params.filter.memo) url += '&search_by_memo='+ params.filter.memo
            if (params.filter.command) url += '&search_by_command='+ params.filter.command
            if (params.filter.startDate) url += '&start_date='+ params.filter.startDate
            if (params.filter.finishDate) url += '&finish_date='+ params.filter.finishDate
        }

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    fetch({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/'+ params.formInput.id + '?'

        // Filters
        if (params.filter)
            if (params.filter.type) url += 'type='+ params.filter.type

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        if (params.formInput.memoId && params.formInput.memoId != '') form.append('memo_id', params.formInput.memoId)
        if (params.formInput.dispositionNumber && params.formInput.dispositionNumber != '') form.append('no_disposition', params.formInput.dispositionNumber)
        if (params.formInput.receivers && params.formInput.receivers.length > 0) form.append('receiver', params.formInput.receivers)
        if (params.formInput.content && params.formInput.content != '') form.append('content', params.formInput.content)
        if (params.formInput.command && params.formInput.command != '') form.append('command', params.formInput.command)
        if (params.formInput.memoRecordId && params.formInput.memoRecordId != '') form.append('memo_record_id', params.formInput.memoRecordId)
        if (params.formInput.isRoot) form.append('is_root', params.formInput.isRoot)

        // Feedback
        let quickReply = (params.formInput.quickReply !== undefined) ? params.formInput.quickReply : false
        form.append('quick_reply', quickReply)
        if (quickReply === true)
            if (params.formInput.docAttachment && params.formInput.docAttachment != '') form.append('doc_attachment', params.formInput.docAttachment)
        
        return (!token)? false : apiBase
            .post(
                'v2/disposition',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    update({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        if (params.formInput.isRead && params.formInput.isRead != undefined) form.append('is_read', params.formInput.isRead)
        if (params.formInput.isDone && params.formInput.isDone != undefined) form.append('is_done', params.formInput.isDone)
        
        return (!token)? false : apiBase
            .put(
                'v3/document/'+ params.formInput.docId, 
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    // Outgoing Disposition
    getOutgoing ({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/'+ params.filter.docId

        // Filters
        if (params.filter) {
            if (params.filter.printId) url += '&print_id='+ params.filter.printId
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    storeOutgoing ({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/print'

        // Filters
        let form = new FormData()
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        if (params.formInput.userPrinterId && params.formInput.userPrinterId != '') form.append('user_printer_id', params.formInput.userPrinterId)
        if (params.formInput.dispositionNumber && params.formInput.dispositionNumber != '') form.append('no_disposition', params.formInput.dispositionNumber)
        if (params.formInput.receivers && params.formInput.receivers.length > 0) form.append('receiver', params.formInput.receivers)
        if (params.formInput.content && params.formInput.content != '') form.append('content', params.formInput.content)
        if (params.formInput.command && params.formInput.command != '') form.append('command', params.formInput.command)
        if (params.formInput.memoRecordId && params.formInput.memoRecordId != '') form.append('memo_record_id', params.formInput.memoRecordId)
        
        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    // Disposition History
    getHistory({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/disposition/history/'+ params.filter.docId

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    getHistoryV1({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/history/'+ params.filter.dispositionId +'?'

        // Filters
        if (params.filter)
            if (params.filter.historyType) url += '&history_type='+ params.filter.historyType

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },
    getMonitoring({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/monitor/'+ params.filter.docId

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    // Receiver
    getReceiver({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/disposition/position?'

        // Filters
        if (params.filter) {
            if (params.filter.positionId && params.filter.positionId != '') url += '&position_id='+ params.filter.positionId
            if (params.filter.position && params.filter.position != '') url += '&position='+ params.filter.position
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },
    getReceiverV1({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/position'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            })
    },

    // Temporer
    getTemporer({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/temporer?'

        // Filters
        if (params.filter) {
            if (params.filter.docId) url += 'doc_id='+ params.filter.docId
            if (params.filter.creatorUserId) url += '&creator_user_id='+ params.filter.creatorUserId
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },
    storeTemporer({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/disposition/temporer'

        // Filters
        let form = new FormData()
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        if (params.formInput.receivers && params.formInput.receivers.length > 0) form.append('position_id', params.formInput.receivers)
        if (params.formInput.content && params.formInput.content != '') form.append('catatan', params.formInput.content)
        if (params.formInput.command && params.formInput.command.length > 0) {
            for (let i = 0; i < params.formInput.command.length; i++) {
                form.append('petunjuk', params.formInput.command[i])
            }
        }
        
        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}