<template>
<CHeader fixed with-subheader danger>
	<CHeaderNav class="d-md-down-none mr-auto">
		<CHeaderNavItem class="px-4">
			<div class="d-flex flex-column text-white small">
				<h6 class="text-brand mb-0">D-TAKAH</h6>
				<p class="small mb-0">KEMENTERIAN PERTAHANAN REPUBLIK INDONESIA</p>
			</div>
		</CHeaderNavItem>
	</CHeaderNav>
    <CHeaderNav class="mr-4">
        <CHeaderNavItem @click.native="redirect('/notification')">
            <CHeaderNavLink>
                <CIcon v-c-tooltip="'Pemberitahuan Tiket'" name="cil-task" class="text-white"/>
                <CBadge color="info" shape="pill" id="notif_ticket_slot">{{ notifCount }}</CBadge>
            </CHeaderNavLink>
		</CHeaderNavItem>
		<CHeaderNavItem @click.native="$store.commit('set', ['sidebarRightMinimize', true])">
			<CHeaderNavLink>
				<font-awesome-icon v-c-tooltip="'Percakapan'" icon="comment-alt" class="text-white"/>
				<CBadge color="info" shape="pill" id="notif_chat_slot"></CBadge>
			</CHeaderNavLink>
		</CHeaderNavItem>
		<DropdownNotif @notif="showNotif('Dokumen')"/>
		<DropdownAccount/>
	</CHeaderNav>
	<CSubheader class="px-4">
		<CBreadcrumbRouter class="border-0 mb-0" />
	</CSubheader>
</CHeader>
</template>

<script>
import {
    createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'
import DropdownNotif from '../dropdown/DropdownNotif'
import DropdownAccount from '../dropdown/DropdownAccount'
export default {
	name: 'Header',
	data() {
		return {
			notifCount:0,
			firstLoad: true,
		}
	},
	components: {
		DropdownNotif,
		DropdownAccount
	},
	mounted() {
        this.getTicketNotification()
		this.checkTokenValid()
    },
	methods: {
		checkTokenValid(){
			var setLoop = true 

			this.$store.dispatch(
				{
					type: 'notification/getTokenStatus'
				}
			).then((res) => {
				if(res.result == false) {
					setLoop = false
					this.$confirm({
						auth: false,
						message: 'Token anda sudah expired, silahkan login ulang?',
						button: {
							yes: 'Ok'
						},
						callback: confirm => {
							if (confirm) {
								this.$store.dispatch('auth/logout').then( (res) => {
									this.redirect('/login')
									location.reload()
								})
							}
						}
					})
				}
			}).catch(err => {
				setLoop = false
			}).finally(() => {
				if(setLoop) {
					setTimeout(() => {
						this.checkTokenValid()
					}, 1000)
				}
			})
		},
		
		redirect (pageRoute) {
			if(this.$router.path != pageRoute){
				this.$router.push(pageRoute)
			}
		},

		getTicketNotification() {
			let old_notif = this.showNotif
			console.log('fetching nofification...')
			this.$store.dispatch({
				type: 'notification/loadNotifTicket',
			}).then((res) => {
				console.log('updating notification')
                if (res && res.result) {
					this.notifCount = res.total_unread

					if(this.notifCount == 0) {
						document.getElementById("notif_ticket_slot").classList.add("hide")
					} else {
						document.getElementById("notif_ticket_slot").classList.remove("hide")
					}
					if (this.firstLoad) {
						this.firstLoad = false
					} else if (old_notif < this.notifCount) {
						this.showNotif('Tiket')
					}
				}
			}).catch(err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getTicketNotification()
				}, 10000)
			})
		},

		showNotif(item){
			let message = "Anda Mendapat Pemberitahuan Baru Terkait " + item
			this.$confirm({
				auth: false,
				message,
				button: {
					no: 'tutup',
				},
			})
		}
	}
}
</script>