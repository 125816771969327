import Vue from 'vue'
import VueRouter from 'vue-router'

const Login = () => import('@/views/auth/Login')
const ForgotPassword = () => import('@/views/auth/ForgotPassword')
const Dashboard = () => import('@/views/main/Dashboard')
const Disposition = () => import('@/views/disposition/Disposition')
const DispositionDetail = () => import('@/views/disposition/Detail')
const Approval = () => import('@/views/approval/Index')
const ApprovalDetail = () => import('@/views/approval/Detail')
const Takah = () => import('@/views/takah/Index')
const TakahFiles = () => import('@/views/takah/Files')
const DocIn = () => import('@/views/document/In')
const DocOut = () => import('@/views/document/Out')
const NotaDinas = () => import('@/views/document/Nota')
const DocFavorite = () => import('@/views/document/Favorite')
const DocAll = () => import('@/views/document/All')
const DocArchive = () => import('@/views/document/Archive')
const DocDetail = () => import('@/views/document/Detail')
const Notification = () => import('@/views/notification/Notification')
const Profile = () => import('@/views/settings/Profile')
const WorkflowConfig = () => import('@/views/settings/Workflow')
const Revision = () => import('@/views/revision/Index')
const Mailbox = () => import('@/views/document/Mailbox')
const Faq = () => import('@/views/help/Faq')
const Report = () => import('@/views/help/Report')
const Book = () => import('@/views/help/Book')
const Template = () =>  import('@/views/settings/Template')
const Email = () =>  import('@/views/settings/EmailConfig')
const Print = () => import('@/views/print/Print')
const Ticket = () => import('@/views/ticket/Ticket')
const SettingTicket = () => import('@/views/ticket/SettingTicket')
const MonitoringDocumentMain = () => import('@/views/monitoring/MonitoringDocumentMain')
const TakahChart = () => import('@/views/chart/TakahChart')
const CategoryChart = () => import('@/views/chart/CategoryChart')
const AgendaChart = () => import('@/views/chart/AgendaChartMain')
const TicketChart = () => import('@/views/chart/TicketChart')
const ActivityChart = () => import('@/views/chart/ActivityChart')
const ProgressChart = () => import('@/views/chart/ProgressChart')
const SystemChart = () => import('@/views/chart/SystemChart')
const MainTicket = () => import('@/views/ticket/MainTicket')
const DownloadPage = () => import('@/views/download_page/DownloadPage')
const BSREPage = () => import('@/views/bsre_page/BSRE_Page.vue')
const DocDeleted = () => import('@/views/document/Deleted')
const SignFormGenerator = () => import('@/views/sign_form_generator/SignFormGenerator')

Vue.use(VueRouter)

const routes = [
	{
		path: '/login',
		name: 'Masuk',
		component: Login
	},{
		path: '/forgot_password',
		name: 'Lupa password',
		component: ForgotPassword
	},{
		path: '/',
		redirect: '/beranda'
	},{
		path: '/beranda',
		name: 'Beranda',
		component: Dashboard
	},{
		path: '/favorite',
		redirect: '/favorite',
		name: 'Favorit',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				component: DocFavorite
			},
			{
				path: 'detail/:id',
				name: 'Detil Favorit',
				component: DocDetail
			}
		]
	},{
		path: '/document_in',
		redirect: '/document_in',
		name: 'Surat Masuk',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				name: 'Surat Masuk',
				component: DocIn
			},
			{
				path: 'detail/:id',
				name: 'Detil Surat Masuk',
				component: DocDetail
			}
		]
	},{
		path: '/document_out',
		redirect: '/document_out',
		name: 'Surat Keluar',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				name: 'Surat Keluar',
				component: DocOut
			},
			{
				path: 'detail/:id',
				name: 'Detil Surat Keluar',
				component: DocDetail
			}
		]
	},{
		path: '/nota_dinas',
		redirect: '/note_dinas',
		name: 'Nota Dinas',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				name: 'Nota Dinas',
				component: NotaDinas
			},
			{
				path: 'detail/:id',
				name: 'Detil Nota Dinas',
				component: DocDetail
			}
		]
	},{
		path: '/document_archive',
		redirect: '/document_archive',
		name: 'Arsip',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				component: DocArchive
			},
			{
				path: 'detail/:id',
				name: 'Detil Surat',
				component: DocDetail
			}
		]
	},{
		path: '/document_deleted',
		redirect: '/document_deleted',
		name: 'Surat Yang Dihapus',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				name: 'Daftar Surat Yang Dihapus',
				component: DocDeleted
			},
			{
				path: 'detail/:id',
				name: 'Detil Surat',
				component: DocDetail
			}
		]
	},{
		path: '/dashboard_chart',
		redirect: '/dashboard_chart/takah_chart',
		name: 'Dashboard Chart',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: 'activity_chart',
				name: 'Activity Chart',
				component: ActivityChart
			},{
				path: 'progress_chart',
				name: 'Progress Chart',
				component: ProgressChart
			},{
				path: 'takah_chart',
				name: 'Takah Chart',
				component: TakahChart
			},{
				path: 'information_chart',
				name: 'Information Chart',
				component: CategoryChart
			},{
				path: 'agenda_chart',
				name: 'Agenda Chart',
				component: AgendaChart
			},{
				path: 'ticket_chart',
				name: 'Ticket Chart',
				component: TicketChart
			},{
				path: 'system_chart',
				name: 'System Chart',
				component: SystemChart
			}
		]
	},{
		path: '/document_email',
		name: 'Surat Surel',
		component: Mailbox
	},{
		path: '/cetak_agenda',
		name: 'Cetak Agenda',
		component: Print,
	},{
		path: '/takah',
		redirect: '/takah',
		name: 'Takah',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: '',
				component: Takah
			},
			{
				path: 'files/:id',
				redirect: 'files/:id',
				component: {
					render(c) {
						return c('router-view')
					}
				},
				children: [
					{
						path: '',
						name: 'Daftar Surat',
						component: TakahFiles
					},{
						path: 'detail/:id',
						name: 'Detil Surat',
						component: DocDetail
					}
				]
			}
		]
	},
	{
		path: '/download_page',
		name: 'Download Page',
		component: DownloadPage,
		props: route => ({ 
			doc_id: route.query.doc_id,
			token: route.query.token,
		})
	},
	{
		path: '/bsre',
		name: 'BSRE Page',
		component: BSREPage,
		props: route => ({ 
			hash_bsre_qr: route.query.hash_bsre_qr,
		})
	},
	

	// Unused
	// {
	// 	path: '/doc_action',
	// 	redirect: '/doc_action/disposition',
	// 	name: 'Tindak Lanjut',
	// 	component: {
	// 		render(c) {
	// 			return c('router-view')
	// 		}
	// 	},
	// 	children: [
	// 		{
	// 			path: 'disposition',
	// 			redirect: 'disposition',
	// 			component: {
	// 				render(c) {
	// 					return c('router-view')
	// 				}
	// 			},
	// 			children: [
	// 				{
	// 					path: '',
	// 					name: 'Disposisi',
	// 					component: Disposition
	// 				},{
	// 					path: ':type/detail/:id',
	// 					name: 'Detil Disposisi',
	// 					component: DispositionDetail
	// 				}
	// 			]
	// 		},{
	// 			path: 'approval',
	// 			name: 'Persetujuan',
	// 			component: Approval
	// 		},{
	// 			path: 'approval_detail/:id',
	// 			name: 'Detil Persetujuan',
	// 			component: ApprovalDetail
	// 		},{
	// 			path: 'revision',
	// 			name: 'Revisi',
	// 			component: Revision
	// 		}
	// 	]
	// },

	{
		path: '/notification',
		name: 'Pemberitahuan',
		component: Notification
	},{
		path: '/setting',
		redirect: '/setting/workflow',
		name: 'Pengaturan',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: 'workflow',
				name: 'Konfigurasi Alur Surat',
				component: WorkflowConfig
			},{
				path: 'template',
				name: 'Template Surat',
				component: Template
			},{
				path: 'email',
				name: 'Email Kotak Masuk',
				component: Email
			}, {
				path: 'setting_ticket',
				name: 'SettingTicket',
				component: SettingTicket
			}, {
				path: 'sign_form_generator',
				name: 'SignFormGenerator',
				component: SignFormGenerator
			}
			
		]
	},{
		path: '/profile',
		name: 'Informasi Akun',
		component: Profile
	},{
		path: '/help',
		redirect: '/help/faq',
		name: 'Bantuan',
		component: {
			render(c) {
				return c('router-view')
			}
		},
		children: [
			{
				path: 'faq',
				name: 'FAQ',
				component: Faq
			},{
				path: 'report',
				name: 'Lapor Keluhan Sistem',
				component: Report
			},{
				path: 'book',
				name: 'Panduan Penggunaan',
				component: Book,
				props: route => ({ 
					token: route.query.token,
				})
			}, {
				path: 'ticket',
				name: 'Tiket',
				component: Ticket
			}, {
				path: 'mainTicket',
				name: 'MainTicket',
				component: MainTicket
			} 
		]
	},
	{
		path: '/monitoring_document',
		name: 'Monitoring Surat',
		component: MonitoringDocumentMain
	},
]

const router = new VueRouter({
	mode: 'history',
	routes
})

export default router
