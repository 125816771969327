<template>
<CFooter :fixed="false">
	<div class="d-flex w-100 justify-content-between">
		<span class="ml-1">&copy; 2020 KEMENTERIAN PERTAHANAN v1.0</span>
		<span class="ml-1">Aplikasi ini didukung oleh <a href="https://bsre.bssn.go.id/" target="_blank">Balai Sertifikasi Elektronik </a></span>
		<span class="ml-1">Pusat Panggilan: 0812-8595-1569</span>
	</div>
</CFooter>
</template>

<script>
export default {
	name: 'Footer'
}
</script>

<style scoped>

a {
    color:black;
}
</style>