<template>
<div
	:class="minimize?'custom-right-sidebar show':'custom-right-sidebar'"
	@update:show="(value) => $store.commit('set', ['sidebarRightShow', value])">
	<CSidebarClose @click.native="closeChat"/>
	<CTabs :active-tab.sync="activeTab">
		<!-- Chat Rooms -->
		<CTab>
			<template slot="title">
				<CIcon name="cil-speech"/>
				Percakapan
			</template>
			<div class="p-1">
				<div v-if="chat.data.length == 0 && !chat.loader" class="text-center p-3">
					<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
					Tidak Ada Pemberitahuan Baru
				</div>
				<div v-else>
					<CListGroup class="mb-10">
						<CListGroupItem
							href="#"
							v-for="(item, index) in chat.data"
							:key="index"
							@click="openRoom(item)"
							class="flex-column align-items-start px-2">
							<div class="d-flex w-100">
								<div class="flex-grow-0 mr-10">
									<div class="media-img-wrap">
										<div v-if="item.roomPhoto != ''" class="avatar avatar-sm content-center">
											<img :src="item.roomPhoto" alt="user" class="avatar-img rounded-circle img-fluid">
										</div>
										<div v-else class="avatar avatar-sm">
											<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
										</div>                                                
									</div>
								</div>
								<div class="flex-grow-1 text-ellipsis">
									<div class="d-flex w-100">
										<div class="mb-1 flex-grow-1">
											<div class="font-weight-bold">{{item.roomName}}</div>
											<div v-if="item.lastMessage.message" class="help-block small">{{ item.lastMessage.message }}</div>
											<div v-else class="help-block small">Belum ada pesan</div>
										</div>
										<div class="flex-grow-0 text-right">
											<vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="item.lastMessage.created_at" locale="id" :long="true"></vue-time-ago>
											<div v-if="item.unreadMessage > 0">
												<CBadge color="danger" shape="pill">{{ item.unreadMessage }}</CBadge>
											</div>
										</div>
									</div>
								</div>
							</div>
						</CListGroupItem>
					</CListGroup>
					<CButton v-if="chat.showMore" @click="showMoreRoomList" class="btn-link btn-sm mb-20 w-100">
						<font-awesome-icon v-if="chat.loader" icon="spinner" spin class="mr-10"/>
						<font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
						Lihat lebih banyak
					</CButton>
				</div>
			</div>
		</CTab>
		<!-- Contact List -->
		<CTab>
			<template slot="title">
				<font-awesome-icon icon="address-book"/>
				Kontak
			</template>
			<div class="p-1">
				<CInput 
					class="mb-20"
					v-model="contactList.filter.search"
					placeholder="Cari kontak..."
					v-on:keyup.enter="searchContact">
					<template #append-content>
						<div class="clickable" @click="searchContact">
							<font-awesome-icon v-if="contactList.loader" icon="spinner" spin/>
							<font-awesome-icon v-else icon="search"/>
						</div>
					</template>
				</CInput>
				<div v-if="contactList.data.length == 0 && !contactList.loader" class="text-center p-3">
					<font-awesome-icon icon="info-circle" size="2x" class="text-info mb-10"/><br/>
					Tidak Ada Pemberitahuan Baru
				</div>
				<div v-else>
					<CListGroup class="mb-10">
						<CListGroupItem
							href="#"
							v-for="(item, index) in contactList.data"
							:key="index"
							@click="createRoom(item)"
							class="flex-column align-items-start px-2">
							<div class="d-flex w-100">
								<div class="flex-grow-0 mr-10">
									<div class="media-img-wrap">
										<div v-if="item.photo_profile != ''" class="avatar avatar-sm content-center">
											<img :src="item.photo_profile" alt="user" class="avatar-img rounded-circle img-fluid">
										</div>
										<div v-else class="avatar avatar-sm">
											<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
										</div>                                                
									</div>
								</div>
								<div class="flex-grow-1 text-ellipsis" style="line-height:3.1">
									{{item.fullname}}
								</div>
							</div>
						</CListGroupItem>
					</CListGroup>
					<CButton v-if="contactList.showMore && contactList.data.length > 5" @click="showMoreContactList" class="btn-link btn-sm mb-20 w-100">
						<font-awesome-icon v-if="contactList.loader" icon="spinner" spin class="mr-10"/>
						<font-awesome-icon v-else icon="chevron-down" class="mr-10"/>
						Lihat lebih banyak
					</CButton>
				</div>
			</div>
		</CTab>
	</CTabs>
	<!-- Chat Window -->
	<div 
		:class="minimizeRoom?'room-toolbar show':'room-toolbar'"
		@update:show="(value) => $store.commit('set', ['roomShow', value])">
		<CSidebarClose @click.native="closeRoom"/>
		<div class="room-content flex-column">
			<div class="flex-grow-1">
				<!-- Chat Header Info -->
				<div class="d-flex w-100">
					<div class="flex-grow-0 mr-10">
						<div class="media-img-wrap">
							<div v-if="chat.roomInfo.roomPhoto != ''" class="avatar avatar-sm content-center">
								<img :src="chat.roomInfo.roomPhoto" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>
							<div v-else class="avatar avatar-sm">
								<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>                                                
						</div>
					</div>
					<div class="flex-grow-1 text-ellipsis" style="line-height:3.1">
						<div class="font-weight-bold">{{chat.roomInfo.roomName}}</div>
					</div>
				</div>
				<hr class="mt-1 mb-2"/>
				<!-- Chat Body -->
				<div class="mt-1">
					<CListGroup id="msg-list" class="mb-10" style="max-height:260px;overflow-y: auto;">
						<CListGroupItem class="py-1 px-3 mb-2 border-0">
							<div class="d-flex flex-column">
								<CButton v-if="message.showMore && message.data.length > 20" @click="showMoreMessageList" class="btn-link btn-sm w-100">
									<font-awesome-icon v-if="message.loader" icon="spinner" spin class="mr-10"/>
									<font-awesome-icon v-else icon="chevron-up" class="mr-10"/>
									Lihat lebih banyak
								</CButton>
							</div>
						</CListGroupItem>
						<!-- Message List -->
						<CListGroupItem v-for="(item, index) in message.data" :key="index" class="py-1 px-3 mb-2 border-0">
							<div class="d-flex flex-column">
								<div :class="(item.userId == userId)?'text-right':'text-left'">
									<vue-time-ago class="vue-fontawesome time-ago-notif" refresh :datetime="item.createdAt" locale="id" :long="true"></vue-time-ago>
									<div :class="(item.userId == userId)?'d-flex flex-row-reverse':'d-flex'">
										<span class="py-1 px-2 bg-light">{{item.message}}</span>
									</div>
								</div>
							</div>
						</CListGroupItem>
					</CListGroup>
				</div>
			</div>
			<!-- Chat Message Input -->
			<div class="flex-grow-0 bg-white">
				<div class="d-flex w-100">
					<div class="flex-grow-0">
						<div class="media-img-wrap">
							<div v-if="profile_picture" class="avatar avatar-sm content-center">
								<img :src="profile_picture" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>
							<div v-else class="avatar avatar-sm">
								<img :src="avatar" alt="user" class="avatar-img rounded-circle img-fluid">
							</div>                                                
						</div>
					</div>
					<div class="flex-grow-1 p-1">
						<CInput 
							class="comment-bar mb-0 "
							v-model="message.input.message"
							placeholder="Tulis pesan..."
							v-on:keyup.enter="postMessage">
							<template #append-content>
								<div class="clickable" @click="postMessage">
									<font-awesome-icon v-if="message.loader" icon="spinner" spin/>
									<font-awesome-icon v-else icon="paper-plane"/>
								</div>
							</template>
						</CInput>
					</div>
				</div>
			</div>
		</div>
	</div>

	


	<CModal :show.sync="showPopUpReminder" :closeOnBackdrop="false">
		<template #header>
			<h5 class="modal-title"> 
				<font-awesome-icon icon="exclamation" class="mr-3"/>Pengingat Sistem</h5>
		</template>
		<div class="p-1"> 
			{{reminderData.message}}
		</div>
	
		<div class="p-1"> 
			Perihal: <b> {{reminderData.title}} </b>
		</div>
		
		<div class="p-1"> 
			Nomor Surat: <b>{{reminderData.doc_no}} </b>
		</div>
		
		<template #footer>
			<CButton @click="closePopupReminderModal()" class="btn-sm">Tutup</CButton>	
			<CButton @click="openDocumentReminder()" color="info" class="btn-sm">Buka Dokumen</CButton>
		
		</template>
	</CModal>
</div>
</template>

<script>
import {
	createNamespacedHelpers,
    mapState,
    mapGetters
} from 'vuex'
import SocketIO from "socket.io-client"

const avatar = require('@/assets/img/avatar.svg')
const logo = require('@/assets/img/logo.png')
export default {
	name: 'SidebarRight',
	data () {
		const chat = {
			loader: false,
			data: [],
			showMore: true,
			showMoreIndex: 0,
			isConnected: false,
			roomInfo: {
				roomName: '',
				roomPhoto: ''
			},
			roomInput: {
				name: '',
				users: []
			}
		}
		const message = {
			loader: false,
			data: [],
			showMore: true,
			showMoreIndex: 0,
			input: {
				roomId: 0,
				message: ''
			},
		}
		const contactList = {
			loader: false,
			data: [],
			showMore: true,
			showMoreIndex: 0,
			filter: {
				search: ''
			}
		}

		return {
			logo,
			avatar,
			chat,
			message,
			contactList,
			activeTab: 0,
			activeRoom: 0,
			showPopUpReminder: false,
			reminderData: {},
			stateLoadMoreRoom: false,
			unReadMsg: 0,
			limit: 5,
			predictRoomNumber: 0
		}
	},
	computed: {
		...mapState('auth', {
			token: 'token',
			userId: 'user_id',
			fullname: 'fullname',
			email: 'email',
			groups: 'groups',
			rank: 'rank',
            position: 'position',
            profile_picture: 'profile_picture_thumb_base64'
        }),
		show () {
			return this.$store.state.sidebarRightShow 
		},
		minimize () {
			return this.$store.state.sidebarRightMinimize 
		},
		minimizeRoom () {
			return this.$store.state.roomMinimize 
		},
		socket () {
			return SocketIO(process.env.VUE_APP_CHAT, {
				transport: ['polling','websocket'],
				transportOptions: {
					polling: {
						extraHeaders: {
							'authorization': 'Bearer ' + this.token
						}
					}
				}
			})
		}
	},
	watch: {
		minimize: {
			deep: true,
			handler: function(newVal) {
				
				if(newVal == false) {
					this.stateLoadMoreRoom = false
				}
			}
		}
	},
	mounted () {
		if (this.activeRoom == 0) this.closeRoom()

        this.socket.on('connect', () => {
			console.log('Connected')
			this.chat.isConnected = true
		})

		this.socket.on('message', (data) => {
			console.log(data)
			console.log('Fetching Message')
			let msgRow = {
				createdAt: data.created_at,
				message: data.message,
				userId: data.user.user_id,
				fullname: data.user.fullname
			}
			this.message.data.push(msgRow)

			setTimeout( e => {
				let msgList = document.getElementById("msg-list")
				msgList.scrollTop = msgList.scrollHeight
			}, 500)
		})
		
        this.socket.on('disconnect', () => {
			console.log('Disconnected')
			this.chat.isConnected = false
		})
		
		this.getNotificationUnreadMessage()
        this.getRoomList()
		this.getContactList()
		this.getNotificationReminder()
	},
	methods: {
		// Conversation Module
		getNotificationUnreadMessage() {
			this.$store.dispatch(
				{
					type: 'chat/getRoom',
					pagination: {
						limit: 1000,
						offset: 0
					}
				}
			).then( res => {
				if (res) {
					var totalUnreadMessage = 0
					res.result.forEach( item => {
						totalUnreadMessage = totalUnreadMessage + item.unread_count
					})
					this.showNotificationBadgeForIconChat(totalUnreadMessage)
				} 
			}).finally(() => {
				setTimeout(() => {
					this.getNotificationUnreadMessage()
				}, 10000)
			})
		},
		getRoomList () {
			this.contactList.loader = true
			this.$store.dispatch(
				{
					type: 'chat/getRoom',
					pagination: {
						limit: this.limit,
						offset: this.chat.showMoreIndex
					}
				}
			).then( res => {
				if (res) {
					res.result.forEach( item => {
						let rowData = {
							roomId: item.room_id,
							roomName: item.name,
							unreadMessage: item.unread_count,
							lastMessage: (item.last_message)?item.last_message:null,
							isPrivate: item.is_private,
							roomPhoto: (item.room_picture_base64 != '' && item.room_picture_base64 != null)? "data:image/jpeg;base64," + item.room_picture_base64:''
						}
						this.chat.data.push(rowData)
					})
					this.chat.showMoreIndex = this.chat.data.length
				} else {
					this.chat.showMore = false
				}
				this.chat.loader = false
			}).finally(() => {
				this.predictRoomNumber = this.predictRoomNumber + this.limit
				if(this.chat.data.length < this.predictRoomNumber) {
					this.chat.showMore = false
				} else {
					this.chat.showMore = true
				}
			
				setTimeout(() => {
					if(this.stateLoadMoreRoom == false) {
						this.predictRoomNumber = 0
						this.reloadRoom()
					}
				}, 10000)
			})
		},
		showNotificationBadgeForIconChat(totalUnreadMessage) {
			var elementId = 'notif_chat_slot'
			if(totalUnreadMessage == 0) {
				document.getElementById(elementId).classList.add('hide')
				document.getElementById(elementId).innerHTML=''
				this.unReadMsg = 0
			} else {
				document.getElementById(elementId).classList.remove('hide')
				document.getElementById(elementId).innerHTML = (totalUnreadMessage > 99) ? '99+' : totalUnreadMessage
				if (this.unReadMsg < totalUnreadMessage) {
					this.showNotif(totalUnreadMessage)
				} else {
					this.unReadMsg = totalUnreadMessage
				}
			}
			
		},
		showMoreRoomList () {
			this.stateLoadMoreRoom = true
			this.getRoomList()
		},
		reloadRoom () {
			this.chat.data = []
			this.chat.showMore = true
			this.chat.showMoreIndex = 0
			this.getRoomList()
		},
		createRoom (item) {
			this.$confirm({
                auth: false,
                message: 'Mulai percakapan dengan pengguna ini?',
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.chat.roomInput.users = [ this.userId, item.userId ]
						this.$store.dispatch(
							{
								type: 'chat/storeRoom',
								formInput: this.chat.roomInput
							}
						).then(res => {
							if (res) {
								this.reloadRoom()
								
								let rowData = {
									roomId: res.result.room_id,
									roomName: item.fullname,
									roomPhoto: ''
								}
								this.activeRoom = res.result.room_id
								this.openRoom(rowData)

								this.activeTab = 0
								this.$toasted.success('Berhasil membuat room').goAway(3000)
							} else {
								this.$toasted.info('Terjadi kesalahan').goAway(3000)
							}
						}).catch(error => {
							this.$toasted.error(error).goAway(3000)
						})
					}
				}
			})
		},
		async getRoomMessage (type) {
			this.message.loader = true
			let data = await this.$store.dispatch(
				{
					type: 'chat/get',
					filter: {
						roomId: this.activeRoom
					},
					pagination: {
						limit: 20,
						offset: this.message.showMoreIndex
					}
				}
			).then( res => {
				if (res) {
					let result = res.result.reverse()
					result.forEach( item => {
						let msgRow = {
							createdAt: item.created_at,
							message: item.message,
							userId: item.user.user_id,
							fullname: item.user.fullname
						}
						if (type == 0) this.message.data.push(msgRow)
						else this.message.data.unshift(msgRow)
					})
					this.message.showMoreIndex = this.message.data.length
					this.message.showMore = (this.message.data.length < 5)?false:true

					setTimeout( e => {
						let msgList = document.getElementById("msg-list")
						msgList.scrollTop = msgList.scrollHeight
					}, 500)
				} else {
					this.message.showMore = false
				}
				this.message.loader = false
			})
		},
		showMoreMessageList () {
			this.getRoomMessage(1)
		},
		openRoom (item) {
			if (this.chat.isConnected) this.closeRoom()
			setTimeout(()=> {
				this.socket.open()

				this.chat.roomInfo.roomName = item.roomName
				this.chat.roomInfo.roomPhoto = item.roomPhoto

				this.message.data = []
				this.message.showMore = true
				this.message.showMoreIndex = 0
				this.message.input.roomId = item.roomId
				this.activeRoom = item.roomId
				if (this.activeRoom > 0) {
					this.getRoomMessage(0)
					this.joinRoom(this.activeRoom)
				}

				this.$store.commit('set', ['roomMinimize', true])
			}, 500)
		},
		joinRoom (room) {
			if (room > 0) {
				console.log('Try to join room', room)
				this.socket.emit('join', { 
					'room_id': room
				}, () => {
					console.log('You are joined to room', room)
				})
			}
		},
		closeChat () {
			this.closeRoom()
			this.$store.commit('set', ['sidebarRightMinimize', false])
		},
		closeRoom () {
			this.leaveRoom(this.activeRoom)
			this.$store.commit('set', ['roomMinimize', false])
			this.message.data = []
		},
		leaveRoom (room) {
			if (room > 0) {
				console.log('Try to leave room', room)
				this.socket.emit('leave', {
					'room_id': room
				}, () => {
					console.log('You are leaved from room ' + room)
					this.socket.close()
					this.message.input.roomId = 0
					this.activeRoom = 0
				})
			}
		},
		postMessage () {
			this.message.loader = true

			if(this.chat.isConnected == false ) {
				this.socket.open()
			}

			this.socket.emit('message', { 
				'room_id': this.message.input.roomId,
				'message': this.message.input.message
			}, () => {
				let msgRow = {
					createdAt: this.$moment(Date.now()).format("Y-MM-DD, HH:mm"),
					message: this.message.input.message,
					userId: this.userId,
					fullname: this.fullname
				}
				this.message.data.push(msgRow)
				setTimeout( e => {
					this.message.input.message = ''
					this.message.loader = false
					let msgList = document.getElementById("msg-list")
					msgList.scrollTop = msgList.scrollHeight
				}, 500)
			})
		},
		
		checkItemGroups(item) {
			if(item.groups == undefined) {
				return "-"
			} else if(item.groups.length > 0) {
				return item.groups
			}
		},

		// Contact Module
		getContactList () {
			this.contactList.loader = true
			this.$store.dispatch(
				{
					type: 'user/getUser',
					pagination: {
						limit: 20,
						offset: this.contactList.showMoreIndex
					},
					order: {
						orderBy: 'fullname',
						orderType: 'asc'
					},
					filter: {
						search: this.contactList.filter.search
					}
				}
			).then( res => {
				if (res) {
					res.result.forEach( item => {
						let rowData = {
							userId: item.user_id,
							fullname: this.returnPositionIfFullnameNull(item),
							email: (item.email)?item.email:'-',
							rank: (item.rank)?item.rank:'-',
							groups: this.checkItemGroups(item),
							position: (item.position)?item.position:'-',
							photo_profile: (item.profile_picture_thumb_base64 != '' && item.profile_picture_thumb_base64 != null)? "data:image/jpeg;base64," + item.profile_picture_thumb_base64:''
						}
						this.contactList.data.push(rowData)
					})
					this.contactList.showMoreIndex = this.contactList.data.length
					this.contactList.showMore = (this.contactList.data.length < 5)?false:true
				} else {
					this.contactList.showMore = false
				}
				this.contactList.loader = false
			})
		},
		returnPositionIfFullnameNull(item){
			if(item.fullname != null) {
				return item.position
			} else {
				return item.position
			}
		},
		showMoreContactList () {
			this.getContactList()
		},
		searchContact () {
			this.contactList.data = []
			this.contactList.showMore = true
			this.contactList.showMoreIndex = 0
			this.getContactList()
		},
		
		getNotificationReminder() {
			this.$store.dispatch({
				type: 'notification/getReminder'
			}).then( res => {
				if(res.result.reminder_id != null) {
				this.reminderData = res.result
				this.showPopUpReminder = true}
			
			}).catch(err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getNotificationReminder()
				}, 10000)
			})
		},
		closePopupReminderModal(){
			this.showPopUpReminder = false

			this.$store.dispatch({
				type: 'notification/closeReminder',
				data: {
					reminder_id: this.reminderData.reminder_id
				}
			}).then( res => {
			
			}).catch(err => {
				console.log('Gagal Menutup Notifikasi Pengingat')
				console.log(err)
			})
		},
		openDocumentReminder() {
			this.showPopUpReminder = false

			this.$store.dispatch({
				type: 'notification/closeReminder',
				data: {
					reminder_id: this.reminderData.reminder_id
				}
			}).then( res => {

				let isForceAccess = localStorage.getItem('isForceAccess')
				if(isForceAccess != null) localStorage.removeItem('isForceAccess')
				localStorage.setItem('isForceAccess', false)

				if(this.reminderData.type == 'Masuk') {
					this.$router.push('/document_in/detail/' + this.reminderData.doc_id)
				} else if(this.reminderData.type == 'Keluar') {
					this.$router.push('/document_out/detail/' + this.reminderData.doc_id)
				} else if (this.reminderData.type == 'Nota Dinas') {
					this.$router.push('/nota_dinas/detail/' + this.reminderData.doc_id)
				}
			}).catch(err => {
				console.log('Gagal Menutup Notifikasi Pengingat')
				console.log(err)
				this.$toasted.error('Gagal Menutup Notifikasi Pengingat, silahkan coba kembali!').goAway(3000)
				
			})

		},

		showNotif(totalUnreadMessage){
			this.unReadMsg = totalUnreadMessage
			let message = "Anda Mendapat Pesan Baru"
			this.$confirm({
				auth: false,
				message,
				button: {
					no: 'tutup',
				},
			})
		}
	}
}
</script>

<style scoped>
.text-brand{
	margin-left:10px;
	line-height:1.9;
	font-weight:600;
	margin-bottom: 0;
}
</style>