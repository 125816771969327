import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    // Approval
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/document/approval?'
        if (params.filter) {
            if (params.filter.userId) url += '&user_id='+ params.filter.userId
            if (params.filter.approvalId) url += '&approval_id='+ params.filter.approvalId
            if (params.filter.choice) url += '&search_by_choice='+ params.filter.choice
            if (params.filter.approvalStatus !== undefined) url += '&approval_status='+ params.filter.approvalStatus
        }
        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }
        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.statusText == 'OK') ? res.data : null
            }).catch( err => {
                return err
            })
    },
    fetch({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/approval/'+ params.formInput.approvalId + '?'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.statusText == 'OK') ? res.data : null
            }).catch( err => {
                console.log('error lah ini')
                return err
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')

        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v2/document/' + params.formInput.docId

        let form = new FormData();
        if (params.formInput.docNo && params.formInput.docNo != '') form.append('doc_no', params.formInput.docNo)
        if (params.formInput.title && params.formInput.title != '') form.append('title', params.formInput.title)

        let isWorkflow = params.formInput.isWorkflow
        if (isWorkflow == 'Ya') {
            form.append('is_workflow', 'yes')
            if (params.formInput.workflow && params.formInput.workflow != '') form.append('workflow', params.formInput.workflow)
        }else{
            form.append('is_workflow', 'no')
            if (params.formInput.tags && params.formInput.tags.length > 0) form.append('approval', params.formInput.tags)
        }
        
        // Temporary Field
        form.append('marks', '{}')

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            });
    },
    setActionApproval({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/document/approval/' + params.formInput.approvalId
        let form = new FormData();
        let actionForm = params.formInput.action
        let revisionState = false
        if (actionForm == 'Setuju') {
            form.append('approve', true)
        } else {
            form.append('approve', false)
            revisionState = (params.formInput.revision === false) ? false : true
            if (params.formInput.message) form.append('message', params.formInput.message)
        }
        form.append('revision', revisionState)
        form.append('is_bsre', params.formInput.isBsre)

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    setActionForceDone({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let url = ""

        let form = new FormData();

        if(params.formInput.verificationId != undefined) {
            url = 'v1/document/verification/' + params.formInput.verificationId
            form.append('verified', true)
            form.append('is_terminate', true)
        } else { // approval condition
            url = 'v1/document/approval/' + params.formInput.approvalId
            form.append('approve', true)
            form.append('revision', false)
            form.append('is_bsre', false)
            form.append('is_terminate', true)
        }

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    setActionForceDoneV2({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        let form = new FormData();

        let url = 'v2/document/verification/' + params.formInput.verificationId
        form.append('verified', true)
        form.append('is_terminate', true)

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    // History
    getWorkflow({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/monitor/workflow?'

        // Filters
        if (params.filter) {
            if (params.filter.docId) url += 'doc_id='+ params.filter.docId
        }

        return (!token)? false : apiBase
            .get(
                url,
                {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                console.log(err.response)
                return false
            })
    },
    // Signature
    storeSignedDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/signed_doc'

        let form = new FormData()
        if (params.formInput.docId && params.formInput.docId != '') form.append('doc_id', params.formInput.docId)
        if (params.formInput.signedFiles && params.formInput.signedFiles.length > 0) form.append('file_signed_base64', params.formInput.signedFiles)
        if (params.formInput.isWorkflow != undefined && params.formInput.isWorkflow != null) form.append('is_workflow', params.formInput.isWorkflow)
        if (params.formInput.with_doc_no != undefined && params.formInput.with_doc_no != null) form.append('with_doc_no', params.formInput.with_doc_no)
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return false
            });
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}