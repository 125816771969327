import { apiBase } from "@/helpers"

const state = {
    notifCount: 0,
    notifTicketCount: 0
}

const actions = {
    async getNotifCount({commit, payload}){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'multipart/form-data'
        }
        let username = localStorage.getItem('username')
        if(token && username){
            apiBase.get(
                'v1/notification?limit=0&offset=0', 
                { headers }
            ).then(async res => {
                let data = await res.data.total_unread
                commit('GET_NOTIF', data)
            }).catch( err => {
                console.log(err)
            })
        }
    },
    readNotif({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification'

        // Filters
        var form = new FormData()
        if (params.formInput.notifIdList && params.formInput.notifIdList.length > 0) form.append('notif_id_list', params.formInput.notifIdList)
        if (params.formInput.operation && params.formInput.operation != '') form.append('operation', params.formInput.operation)
        if (params.formInput.markAll != undefined) form.append('mark_all', params.formInput.markAll)
        
        return (!token)? false : apiBase
            .put(url, form, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    loadNotif({commit, payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification?'

        // Filters
        if (data.filter) {
            if (data.filter.isCleared !== undefined) url += '&is_cleared='+ data.filter.isCleared
        }

        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        
        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    reminderUser({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification/reminder'

        // Filters
        var form = new FormData()
        form.append('user_id', params.data.user_id)
        form.append('doc_id', params.data.doc_id)
        
        return (!token)? false : apiBase
            .post(url, form, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getReminder({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification/reminder'
        return (!token)? false : apiBase
            .get(url, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    closeReminder({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification/reminder/'+params.data.reminder_id
        
        var form = new FormData()
        return (!token)? false : apiBase
            .put(url, form, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    loadNotifTicket({commit, payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/notification?'

        // Filters
        if (data.filter) {
            if (data.filter.isCleared !== undefined) url += '&is_cleared='+ data.filter.isCleared
        }

        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    readNotifTicket({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/notification/' + params.formInput.id

        // Filters
        var form = new FormData()
        if (params.formInput.isClear) form.append('is_clear', params.formInput.isClear)


        return (!token)? false : apiBase
            .put(url, form, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getTokenStatus({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/notification/token_status'


        return (!token)? false : apiBase
            .get(url, {headers})
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    }
}

const mutations = {
    GET_NOTIF(state, payload){
        state.notifCount = payload
    },
    GET_NOTIF_TICKET(state, payload){
        state.notifTicketCount = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}