import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: -1
}

const actions = {

    getApiKey({commit,payload}, params ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/twain'
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}