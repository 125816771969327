import Vue from 'vue'
import Vuex from 'vuex'
import modules from '@/store/modules'; 

Vue.use(Vuex)

const state = {
	sidebarShow: 'responsive',
	sidebarMinimize: false,
	sidebarRightShow: 'responsive',
	sidebarRightMinimize: false,
	roomShow: 'responsive',
	roomMinimize: false,
}

const mutations = {
	toggleSidebarDesktop (state) {
		const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarOpened ? false : 'responsive'
	},
	toggleSidebarRight (state) {
		const sidebarRightClosed = [true, 'responsive'].includes(state.sidebarRightShow)
		state.sidebarRightShow = sidebarRightClosed ? false : 'responsive'
	},
	toggleSidebarMobile (state) {
		const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
		state.sidebarShow = sidebarClosed ? true : 'responsive'
	},
	set (state, [variable, value]) {
		state[variable] = value
	}
}

const actions = {}

export default new Vuex.Store({
	strict: true,
	state,
	mutations,
	actions,
	modules
})
