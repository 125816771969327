<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
	<template #toggler>
		<CHeaderNavLink>
			<div class="c-avatar">
				<img :src="(profile_picture)? profile_picture:avatar" class="c-avatar-img"/>
			</div>
			<div class="d-flex flex-column text-white small ml-10">
				<h6 class="text-brand mb-0">{{ position }}</h6>
				<p v-if="isSPRI" class="text-brand mb-0">SPRI</p>
			</div>
		</CHeaderNavLink>
	</template>

	<CDropdownHeader tag="div" class="text-center">
		<strong>Account</strong>
    </CDropdownHeader>
	<CDropdownItem @click.native="redir('/profile')">
		<CIcon name="cil-user"/> {{ fullname }}
	</CDropdownItem>
	<CDropdownDivider/>
	<CDropdownItem @click.native="doLogout()">
		<CIcon name="cil-lock-locked"/> Logout
	</CDropdownItem>
</CDropdown>
</template>

<script>
import {
    mapState
} from 'vuex'

const avatar = require('@/assets/img/avatar.svg')
export default {
	name: 'DropdownAccount',
	data () {
		return { 
			avatar,
			sLogout : false
		}
	},
    methods: {
		doLogout(){
            this.$confirm({
                auth: false,
                message: 'Apakah Anda yakin ingin keluar dari sesi ini?',
                button: {
                    no: 'Tidak',
                    yes: 'Ya'
				},
				callback: confirm => {
					if (confirm) {
						this.$store.dispatch('auth/logout').then( (res) => {
							this.redir('/login')
						})
					}
				}
			})
		},
		redir (message) {
			if(this.$router.path != message){
				this.$router.push(message)
			}
		}
	},
	computed: {
        ...mapState('auth', {
			fullname: 'fullname',
			position: 'position',
            profile_picture: 'profile_picture_thumb_base64'
		}),
		isSPRI() {
			let data = this.position.substring(0, 4)
			if(data == "spri" || data == 'Spri') {
				return true
			} else {
				return false
			}
		}
    }
}
</script>

<style scoped>
.c-icon {
	margin-right: 0.3rem;
}
</style>