<template>
<div class="c-app">
	<Sidebar/>
    <CWrapper>
		<Header/>
		<div class="c-body">
			<main class="c-main">
				<CContainer fluid>
					<vue-page-transition name="fade">
						<router-view></router-view>
					</vue-page-transition>
				</CContainer>
			</main>
			<Footer/>
		</div>
	</CWrapper>
	<SidebarRight/>
</div>
</template>

<script>
import Sidebar from './Sidebar'
import SidebarRight from './SidebarRight'
import Header from './Header'
import Footer from './Footer'

export default {
	name: 'Container',
	components: {
		Sidebar,
		SidebarRight,
		Header,
		Footer
	}
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>