import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/attribute/faq?'

        // Filters
        if (params.pagination) {
            if (params.pagination.limit) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    postReport({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/attribute/cs'

        // Filters
        let form = new FormData()
        if (params.formInput.problem && params.formInput.problem != '') form.append('problem', params.formInput.problem)

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            }).catch( err => {
                return err
            })
    },

    getIndexBook({commit, payload}, params) {

        let token = ''
        if(params.token != '') {
            token = params.token
        } else {
            token = localStorage.getItem('token')
        }
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/attribute/index_book'


        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}