import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    // Room
    getRoom({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/chat/room?'
        if (params.filter)
            if (params.filter.name) url += '&name='+params.filter.name

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            })
    },
    storeRoom({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData();
        if (params.formInput.name && params.formInput.name != '') form.append('name', params.formInput.name)
        if (params.formInput.users && params.formInput.users.length > 0) form.append('user_id_list', params.formInput.users)
        
        return (!token)? false : apiBase
            .post(
                'v1/chat/room',
                form,
                { headers }
            ).then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            });
    },
    updateRoom({commit,payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData();
        if (params.formInput.operation && params.formInput.operation != '') form.append('operation', params.formInput.operation)
        if (params.formInput.name && params.formInput.name != '') form.append('name', params.formInput.name)
        if (params.formInput.users && params.formInput.users.length > 0) form.append('user_id_list', params.formInput.users)
        if (params.formInput.photo && params.formInput.photo.length > 0) form.append('photo', params.formInput.photo)
        
        return (!token)? false : apiBase
            .put(
                'v1/chat/room/'+ params.formInput.roomId,
                form,
                { headers }
            ).then( res => {
                return (!res.data) ? res.data : null
            }).catch( err => {
                return err
            });
    },

    // Chat
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/chat/message?'
        if (params.filter)
            if (params.filter.roomId) url += '&room_id='+ params.filter.roomId
            if (params.filter.message) url += '&message='+ params.filter.message

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData();
        if (params.formInput.roomId && params.formInput.roomId != '') form.append('room_id', params.formInput.roomId)
        if (params.formInput.message && params.formInput.message != '') form.append('message', params.formInput.message)
        
        return (!token)? false : apiBase
            .post(
                'v1/user_position',
                form,
                { headers }
            ).then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            });
    },
    destroy({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        return (!token)? false : apiBase
            .delete(
                'v1/chat/message/'+ params.formInput.chatId, 
                { headers }
            ).then( res => {
                return (res.data) ? res.data : null
            }).catch( err => {
                return err
            });
    }
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}