import { apiBase } from '@/helpers'

const state = {
    uploadProgressBtn: 'Memproses ... (0%)'
}

const actions = {
    getBSREStatusSignDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/sign_document?doc_id=' + params.docId

        if(params.docId == undefined) {
            return false
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    regisSignDocument({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/regis_sign_document?doc_id=' + params.docId

        let form = new FormData()
        

        form.append('doc_id', params.docId)

        return (!token)? false : apiBase
            .post(url, form,  { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getBSREDetailData({commit, payload}, params) {
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/qr?hash_bsre_qr=' + params.hash_bsre_qr


        return apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },


    requestRefillPassphrase({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/refill_passphrase'

        let form = new FormData()
        

        form.append('doc_id', params.docId)
        form.append('receiver_user_id', params.userId)

        return (!token)? false : apiBase
            .post(url, form,  { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    
    getBSRELog({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/bsre_log?doc_id=' + params.docId


        return apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    createSignatureBox({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/box_signature'

        let form = new FormData()

        var currentData = params.currentData
        form.append('atas_nama', currentData.signBy)
        form.append('nama_jabatan', currentData.positionName)
        form.append('nama_lengkap', currentData.fullName)
        form.append('pangkat', currentData.rank)
        form.append('nip', currentData.nipOrNrp)
        form.append('file_base64', currentData.fileBase64)

        return (!token)? false : apiBase
            .post(url, form,  { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    
    getSignatureBoxList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/bsre/box_signature'
        
        url += "?limit=" + params.pagination.limit
        url += "&offset=" + params.pagination.offset


        return apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    
    updateSignatureBox({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()

        var currentData = params.currentData
        let url = 'v1/bsre/box_signature/'+ currentData.boxId 
        form.append('atas_nama', currentData.signBy)
        form.append('nama_jabatan', currentData.positionName)
        form.append('nama_lengkap', currentData.fullName)
        form.append('pangkat', currentData.rank)
        form.append('nip', currentData.nipOrNrp)
        form.append('file_base64', currentData.fileBase64)

        return (!token)? false : apiBase
            .put(url, form,  { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    
    deleteSignatureBox({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        var currentData = params.currentData
        let url = 'v1/bsre/box_signature/'+ currentData.boxId 

        return (!token)? false : apiBase
            .delete(url,  { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
}


const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses ... (' + payload + ')'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
