import { apiBase } from "@/helpers"

const state = {}

const actions = {
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = '/v1/document/revision?'

        // Filters
        if (params.filter) {
            if (params.filter.docFileId) url += '&doc_file_id='+ params.filter.docFileId
            if (params.filter.docId) url += '&doc_id='+ params.filter.docId
        }
        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }
        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return err
            })
    },
    fetch({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = '/v1/document/revision/'+ params.filter.revisionId + '?'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return err
            })
    },
    update({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = '/v1/document/revision/'+ params.formInput.revisionId + '?'

        // Filters
        let form = new FormData()
        if (params.formInput.isPublishRollback !== undefined) form.append('is_publish_rollback', params.formInput.isPublishRollback)
        if (params.formInput.docFileId && params.formInput.docFileId != '') form.append('doc_file_id', params.formInput.docFileId)
        if (params.formInput.docNo && params.formInput.docNo != '') form.append('doc_no', params.formInput.docNo)
        if (params.formInput.title && params.formInput.title != '') form.append('title', params.formInput.title)
        if (params.formInput.isWorkflow && params.formInput.isWorkflow != '') form.append('is_workflow', params.formInput.isWorkflow)
        if (params.formInput.workflow && params.formInput.workflow != '') form.append('workflow', params.formInput.workflow)
        if (params.formInput.approval && params.formInput.approval != '') form.append('approval', params.formInput.approval)


        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return err
            })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}