<template>
<CDropdown inNav class="c-header-nav-items" placement="bottom-end" add-menu-classes="pt-0">
	<template #toggler>
		<CHeaderNavLink>
			<CIcon v-c-tooltip="'Pemberitahuan'" name="cil-bell" class="text-white"/>
			<CBadge color="info" shape="pill" id="notif_count_slot">{{ notifCount }}</CBadge>
		</CHeaderNavLink>
	</template>

	<CDropdownHeader tag="div" class="text-center">
		<strong>Pemberitahuan</strong>
    </CDropdownHeader>

	<CDropdownItem
		v-for="(item, index) in notifItems"
		:key="index"
		@click="toDetail(item)">
		<div style="width: 600px">
			<div class="d-flex">
				<div class="flex-grow-1" style="min-width:80%">
					<div class="small">
						<h6>Surat {{ item.doc_type }}</h6>
						<b>{{item.user.position}}</b> 
						<p class="mb-0" style="word-wrap: break-word;  white-space: break-spaces">{{item.message}}</p>
					</div>
				</div>
				<div class="flex-grow-0 ml-10">
					<span class="text-right">
						<vue-time-ago
							:datetime="(item.created_at)"
							:locale="locale"
							:long="longString"
							class="vue-fontawesome time-ago-notif"
							refresh/>
					</span>
				</div>
			</div>
		</div>
	</CDropdownItem>

	<CDropdownDivider/>

	<CDropdownItem @click="redirect('/notification')">
		Lihat Lebih
	</CDropdownItem>
</CDropdown>
</template>

<script>
export default {
	name: 'DropdownNotif',
	data() {
        return {
			notifItems: [],
			notifCount: 0,
			longString: true,
			locale: "id",
			showLimit: 10,
			firstLoad: true,
        }
	},
	mounted() {
        this.getNotification()
    },
	methods: {
		getNotification () {
			console.log('fetching nofification...')
			let old_notif = this.notifCount
			this.$store.dispatch({
				type: 'notification/loadNotif',
				filter: { isCleared: false }
			}).then((res) => {
				console.log('updating notification')
				if (res && res.result) {
					const data = res.result
					var unreadNotif = []
					const limit = (data.length >= 10) ? this.showLimit : data.length
					for (let i = 0; i < limit; i++) {
						const notifProp = data[i]
						unreadNotif.push(notifProp)
					}
					this.notifItems = unreadNotif
					console.log(unreadNotif)
					this.notifCount = data.length

					if(this.notifCount == 0) {
						document.getElementById("notif_count_slot").classList.add("hide")
					} else {
						document.getElementById("notif_count_slot").classList.remove("hide")
					}
					if (this.firstLoad) {
						this.firstLoad = false
					} else if (old_notif < this.notifCount) {
						this.$emit('notif')
					}
				}
			}).catch(err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getNotification()
				}, 10000)
			})
		},
		toDetail (notificationProp) {
			let notifId = notificationProp.notif_id
			let docId = notificationProp.doc_id
			this.$store.dispatch({
				type: 'notification/readNotif',
				formInput: {
					notifIdList: [notifId],
					operation: 'read',
					markAll: false
				}
			}).finally(() => {
				// Get document type to determine route path
				this.$store.dispatch({
					type: 'document/getDocument',
					pagination: {
						limit: 1,
						offset: 0
					},
					filter: {
						docId: docId
					}
				}).then( res => {
					const documentProp = res.result[0]
					let docType = documentProp.type
					let redirPath = (docType == 'Masuk') ? '/document_in' : '/document_out'
					this.$router.push(redirPath +'/detail/'+ docId)
				})
			})
		},
		redirect (pageRoute) {
			if(this.$router.path != pageRoute){
				this.$router.push(pageRoute)
			}
		}
	},
}
</script>

<style scoped>
.c-icon {
	margin-right: 0.3rem;
}
</style>