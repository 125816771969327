<template>
<CSidebar fixed :minimize="minimize" :show="show" @update:show="(value) => $store.commit('set', ['sidebarShow', value])">
	<CSidebarBrand class="d-md-down-none" to="/" style="background-color:#C62626">
		<div class="c-sidebar-brand-full">
			<div class="d-flex">
				<img class="brand-img" :src="logo" alt="brand" height="50"/>
				<h6 class="text-brand">KEMENTERIAN PERTAHANAN</h6>
			</div>
		</div>
		<CIcon 
			class="c-sidebar-brand-minimized" 
			name="logo" 
			size="custom-size"
			:src="logo"
			:height="35" 
			viewBox="0 0 110 134"/>
	</CSidebarBrand>

	<CRenderFunction 
		flat :content-to-render="menuItems" 
		style="background-color:#CB3B3B" />
</CSidebar>
</template>

<script>
const logo = require('@/assets/img/logo.png')
import nav from './_nav'
import { mapState } from 'vuex'

export default {
	name: 'Sidebar',
	nav,
	computed: {
		...mapState('auth', {
			username: 'username',
		}),
		show () {
			return this.$store.state.sidebarShow 
		},
		minimize () {
			return this.$store.state.sidebarMinimize 
		},
	},
	data() {
        return {
			sidebarMenuCount: {
				incoming: null,
				outgoing: null
			},
            logo:logo,
			showPopUpReminder: false,
			reminderData: {},

			menuItems: []
        }
	},
	mounted() {
		this.menuItems = this.checkVisibilityMenuItems(nav)
		this.getDocCountSummary()
		this.getTicketDoneCount()
	},
	methods: {
		checkVisibilityMenuItems(items) {
			var newItems = []
			items.forEach(item=> {
				if(item._children != undefined) {
					item._children = this.checkVisibilityMenuItems(item._children)
				}
				if(item.id == undefined) {
					newItems.push(item)
				} else if(item.id == 'document_deleted') {
					var username = this.username
					var lowerCase = username.toLowerCase()
					let result = lowerCase.includes("staff_tu");
					if(result) {
						newItems.push(item)
					}
				}
			})
			return newItems
		},
		showBadgeData(totalNotif, elementId){
			if(totalNotif == 0) {
				document.getElementById(elementId).classList.add("hide")
			} else {
				document.getElementById(elementId).classList.remove("hide")
				document.getElementById(elementId).innerHTML = (totalNotif > 99) ? '99+' : totalNotif
			}
		},
		getDocCountSummary () {
			// Document In
			this.$store.dispatch({
				type: 'document/getDocumentCountOnProcess'
			}).then( res => {
				this.showBadgeData(res.result.Count_Document_In, "document_in_slot")
				this.showBadgeData(res.result.Count_Document_Out, "document_out_slot")
				this.showBadgeData(res.result.Count_Document_Nota_Dinas, "nota_dinas_slot")

			}).catch(err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getDocCountSummary()
				}, 10000)
			})
		},
		getTicketDoneCount () {
			this.$store.dispatch({
				type: 'ticket/getTicketDoneCount'
			}).then( res => {
				this.showBadgeData(res.total_count, "ticket_notif_slot")
			}).catch(err => {
				console.log('Failed to fetch notification data')
				console.log(err)
			}).finally(() => {
				setTimeout(() => {
					this.getTicketDoneCount()
				}, 10000)
			})
		},
	}
}
</script>

<style scoped>
.text-brand{
	margin-left:10px;
	line-height:1.9;
	font-weight:600;
	margin-bottom: 0;
	width:min-content;
}
</style>