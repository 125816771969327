import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    getDocSummary({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/summary/doc_summary?'
        
        if(params.filter) {
            if(params.filter.startDate) url += "&start_date=" + params.filter.startDate
            if(params.filter.endDate) url += "&end_date=" + params.filter.endDate
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getLastProcess({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/summary/last_proccess?'
        if(params.filter) {
            if(params.filter.limit) url += "limit=" + params.filter.limit
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getChart({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/summary/chart?'
        if(params.filter) {
            if(params.filter.startDate) url += "&start_date=" + params.filter.startDate
            if(params.filter.endDate) url += "&end_date=" + params.filter.endDate
            if(params.filter.chartType) url += "&chart_type=" + params.filter.chartType
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getAllSummaryDoc({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/summary/all_doc?'

        if(params.filter) {
            let filter = params.filter

            if(filter.startDate) url += '&start_date='+filter.startDate
            if(filter.endDate) url += '&end_date='+filter.endDate

            if(filter.satkerId) url += '&satker_id='+filter.satkerId.satker_id

            if(filter.takahId) url += '&takah_id='+filter.takahId.takah_id
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getVarData({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/query/constanta?'

        if(params.filter) {
            let filter = params.filter

            if(filter.group) url += '&group='+filter.group
            if(filter.constantaId) url += '&constanta_id='+filter.constantaId
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getSystemData({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/summary/insight_system?'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}