import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    getAllTemplate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template'

        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template?'

        // Filters
        if (params.filter) {
            if (params.filter.templateName) url += '&template_name='+ params.filter.templateName
            if (params.filter.categoryId) url += '&category_id='+ params.filter.categoryId
        }
        if (params.pagination) {
            if(params.pagination.limit) url += '&limit='+ params.pagination.limit
            if(params.pagination.offset) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    fetch({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template/'+ params.formInput.templateId+'?'

        // Filters
        if(params.formInput.outputType) url += 'output_type='+params.formInput.outputType
        
        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType: (params.formInput.outputType == 'preview')?'blob':'json'
                }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    create({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template'

        // Filters
        let form = new FormData();
        if (params.formInput.templateFile && params.formInput.templateFile.length > 0) form.append('template_file', params.formInput.templateFile[0])
        if (params.formInput.templateName && params.formInput.templateName != '') form.append('template_name', params.formInput.templateName)
        if (params.formInput.categoryId && params.formInput.categoryId != '') form.append('category_id', params.formInput.categoryId)
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    destroy({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/template/' + params.formInput.templateId
        
        return (!token)? false : apiBase
            .delete(
                url, 
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    }
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}