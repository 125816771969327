import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './assets/plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'

import { iconsSet as icons } from './assets/icons/icons.js'
import CoreuiVue from '@coreui/vue'

import VueSignaturePad from 'vue-signature-pad'
import InfiniteLoading from 'vue-infinite-loading'
import CoreuiVueCharts from '@coreui/vue-chartjs'

import './assets/plugins/toasted'
import './assets/plugins/fontawesome'
import './assets/plugins/datepicker'
import './assets/plugins/moment'
import './assets/plugins/vue/vueConfirmDialog'
import './assets/plugins/vue/vuePageTransition'
import './assets/plugins/vue/vueTimeAgo'
import './assets/plugins/vue/vueMultiSelect'

Vue.use(CoreuiVue)
Vue.use(VueSignaturePad)
Vue.use(CoreuiVueCharts)
Vue.config.performance = true
Vue.config.productionTip = false
Vue.prototype.$log = console.log.bind(console)

new Vue({
    router,
    icons,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
