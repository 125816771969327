import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow?'

        // FIlters
        if (params.filter) {
            if (params.filter.workflowConfig) url += '&workflow='+ params.filter.workflowConfig
            
            if(params.filter.isDisposition != undefined) {
                url += '&is_disposition='+ params.filter.isDisposition
           
            }
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        if (params.order)
            if(params.order.orderType) url += '&order_type='+params.order.orderType

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow'

        // Filters
        let form = new FormData()
        if (params.formInput.isDisposition != undefined) form.append('is_disposition', params.formInput.isDisposition)
        if (params.formInput.workflowConfig && params.formInput.workflowConfig != '') form.append('config_workflow', params.formInput.workflowConfig)
        if (params.formInput.positionId && params.formInput.positionId.length > 0) form.append('position_id', params.formInput.positionId)
        if (params.formInput.positionIdDraft && params.formInput.positionIdDraft != '') form.append('position_id_draft', params.formInput.positionIdDraft)
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    update({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow/'+ params.formInput.workflowId

        // Filters
        let form = new FormData()
        if (params.formInput.workflowConfig && params.formInput.workflowConfig != '') form.append('config_workflow', params.formInput.workflowConfig)
        if (params.formInput.positionId && params.formInput.positionId.length > 0) form.append('position_id', params.formInput.positionId)
        if (params.formInput.positionIdDraft && params.formInput.positionIdDraft != '') form.append('position_id_draft', params.formInput.positionIdDraft)

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    destroy({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow/'+ params.formInput.workflowId
        
        return (!token)? false : apiBase
            .delete(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? true : false
            })
    },
    makeItPublish({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow/publish/'+ params.formInput.workflowId
        let form = new FormData()
        form.append('is_publish_wf', true)
        
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },
    
    makeItPrivate({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/workflow/publish/'+ params.formInput.workflowId
        let form = new FormData()
        form.append('is_publish_wf', false)
        
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    }
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}