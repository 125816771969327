<template>
<div>
	<vue-page-transition name="fade">
		<router-view></router-view>
	</vue-page-transition>
</div>
</template>

<script>
export default {
	name: 'ContainerAuth',
	components: { }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
	transition: opacity 0.3s;
}
.fade-enter,
.fade-leave-to {
	opacity: 0;
}
</style>