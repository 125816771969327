export default [
	{
		_name: 'CSidebarNav',
		_children: [
			{
				_name: 'CSidebarNavItem',
				name: 'Beranda',
				route: '/',
				icon: 'cil-speedometer',
				to: '/'
			},{
				_name: 'CSidebarNavItem',
				name: 'Surat Masuk',
				route: '/document_in',
				icon: 'cil-file',
				to: '/document_in',
				badge: {
					color: 'warning',
					text: '0',
					id: 'document_in_slot'
				},
			},{
				_name: 'CSidebarNavItem',
				name: 'Surat Keluar',
				route: '/document_out',
				icon: 'cil-file',
				to: '/document_out',
				badge: {
					color: 'warning',
					text: '0',
					id: 'document_out_slot'
				},
			},{
				_name: 'CSidebarNavItem',
				name: 'Nota Dinas',
				route: '/nota_dinas',
				icon: 'cil-file',
				to: '/nota_dinas',
				badge: {
					color: 'warning',
					text: '0',
					id: 'nota_dinas_slot'
				},
			},{
				_name: 'CSidebarNavItem',
				name: 'Arsip',
				route: '/document_archive',
				icon: 'cil-folder',
				to: '/document_archive'
			},{
				_name: 'CSidebarNavItem',
				name: 'Surat Dihapus',
				route: '/document_deleted',
				icon: 'cil-trash',
				to: '/document_deleted',
				id: 'document_deleted'
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Lampiran Email',
				route: '/document_email',
				icon: 'cil-folder',
				to: '/document_email'
			},
			{
				_name: 'CSidebarNavDropdown',
				name: 'Dashboard',
				icon: 'cil-chart-pie',
				items: [
					{
						name: 'Aktivitas',
						to: '/dashboard_chart/activity_chart'
					},{
						name: 'Progress Dokumen',
						to: '/dashboard_chart/progress_chart'
					},{
						name: 'Takah',
						to: '/dashboard_chart/takah_chart'
					},{
						name: 'Informasi Dokumen',
						to: '/dashboard_chart/information_chart'
					},{
						name: 'Agenda',
						to: '/dashboard_chart/agenda_chart'
					},{
						name: 'Tiket',
						to: '/dashboard_chart/ticket_chart'
					},{
						name: 'Sistem',
						to: '/dashboard_chart/system_chart'
					},
				]
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Monitoring Surat',
				route: '/monitoring_document',
				icon: 'cil-monitor',
				to: '/monitoring_document'
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Cetak Agenda',
				route: '/cetak_agenda',
				icon: 'cil-print',
				to: '/cetak_agenda'
			},
			{
				_name: 'CSidebarNavItem',
				name: 'Favorit',
				route: '/favorite',
				icon: { 
					name: 'cil-star',
					class: 'text-yellow' 
				},
				to: '/favorite'
			},{
				_name: 'CSidebarNavItem',
				name: 'Takah',
				route: '/takah',
				icon: { 
					name: 'cil-folder',
					class: 'text-success' 
				},
				to: '/takah'
			},{
				_name: 'CSidebarNavItem',
				name: 'Pemberitahuan',
				route: '/notification',
				icon: 'cil-bell',
				to: '/notification'
			},{
				_name: 'CSidebarNavDropdown',
				name: 'Pengaturan',
				icon: 'cil-settings',
				items: [
					{
						name: 'Konfigurasi Alur Surat',
						to: '/setting/workflow'
					},{
						name: 'Template Surat',
						to: '/setting/template'
					},{
						name: 'Email Kotak Masuk',
						to: '/setting/email'
					}, {
						name: 'Pengaturan Tiket',
						to: '/setting/setting_ticket'
					}, {
						name: 'Generator Kotak Tanda Tangan',
						to: '/setting/sign_form_generator'
					}
				]
			},{
				_name: 'CSidebarNavItem',
				name: 'Info Akun',
				route: '/profile',
				icon: 'cil-user',
				to: '/profile'
			},{
				_name: 'CSidebarNavDropdown',
				name: 'Pusat Bantuan',
				icon: 'cil-chat-bubble',
				items: [
					{
						name: 'FAQ',
						to: '/help/faq'
					},{
						name: 'Lapor Keluhan Sistem',
						to: '/help/report'
					},{
						name: 'Panduan Penggunaan',
						to: '/help/book'
					}, {
						name: 'Tiket',
						route: '/help/mainTicket',
						to: '/help/mainTicket',
						badge: {
							color: 'warning',
							text: '0',
							id: 'ticket_notif_slot'
						}
					},
				]
			}
		]
	}
]