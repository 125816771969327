import { apiBase } from "@/helpers"

const state = {}

const actions = {
    loadData({commit, payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah?'

        // Filters
        if (data.filter) {
            url += 'access='+ (data.filter && data.filter.access !== "")? data.filter.access : "limited"
            if(data.filter.takah_pid) url += '&takah_pid=' + data.filter.takah_pid
            if(data.filter.takah_id) url += '&takah_id=' + data.filter.takah_id
        }

        if (data.pagination) {
            if (data.pagination.limit) url += '&limit='+ data.pagination.limit
            if (data.pagination.offset) url += '&offset='+ data.pagination.offset
        }
        
        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah'

        // Form Filter
        let form = new FormData()
        if (params.formInput) {
            if (params.formInput.name != '') form.append('takah', params.formInput.name)
            if (params.formInput.desc != '') form.append('description', params.formInput.desc)
            if (params.formInput.pid != '' && params.formInput.pid != undefined) form.append('takah_pid', params.formInput.pid)
            if (params.formInput.no != '') form.append('takah_no', params.formInput.no)
        }
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    update({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/'+ params.formInput.takahId

        // Form Filter
        let form = new FormData()
        if (params.formInput) {
            if (params.formInput.name !== "") form.append('takah', params.formInput.name)
            if (params.formInput.desc !== "") form.append('description', params.formInput.desc)
            if (params.formInput.no != '') form.append('takah_no', params.formInput.no)
        }

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    destroy({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/'+ params.formInput.takahId
        
        return (!token)? false : apiBase
            .delete(
                url, 
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    store_files({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/doc_takah/'+ params.formInput.takahId

        // Form Filter
        let form = new FormData()
        if (params.formInput) {
            if (params.formInput.docId.length > 0) form.append('doc_id', params.formInput.docId.join())
        }

        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    destroy_files({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/doc_takah/'+ params.formInput.takahId

        // Form Filter
        let form = new FormData()
        if (params.formInput) {
            if (params.formInput.docId.length > 0) form.append('doc_id', params.formInput.docId.join())
        }

        return (!token)? false : apiBase
            .delete(
                url,
                { headers, data:form }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },

    getTakahChatList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/comment?'

        url += 'takah_id=' + params.filter.takahId
        url += '&limit=10000'
        url += '&offset=0'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    postTakahComment({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/takah/comment'

        let form = new FormData()
        let takahData = data.formInput
        form.append('takah_id', takahData.takahId)
        form.append('message', takahData.message)

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },
}

const mutations = {}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}