import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    get({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/registration_email?'

        // Filters
        if (data.filter) {
            if (data.filter.satkerId) url += '&satker_id' + data.filter.satkerId
            if (data.filter.email) url += '&email=' + data.filter.email
        }

        if (data.pagination) {
            if (data.pagination.limit) url += '&limit=' + data.pagination.limit
            if (data.pagination.offset) url += '&offset=' + data.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    fetch({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/registration_email/' + data.filter.emailId

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    store({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/registration_email'

        // Filters
        let form = new FormData()
        form.append('email', data.formInput.email)
        form.append('password', data.formInput.password)
        if (data.formInput.satkerId) form.append('satker_id', data.formInput.satkerId)
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    update({commit,payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/registration_email/'+ params.formInput.id_email

        

        // Filters
        let form = new FormData()
        if (params.formInput.email && params.formInput.email != '') {
            var newEmail = params.formInput.email 
            if(params.formInput.newEmail && params.formInput.newEmail != '') {
                newEmail = params.formInput.newEmail   
            }
            form.append('email', newEmail)
            if (params.formInput.password) form.append('password', params.formInput.password)
            if (params.formInput.is_active != null) form.append('is_active', params.formInput.is_active)
        }
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            })
    },
    deleteEmailConfig({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        return (!token)? false : apiBase
            .delete(
                'v1/document/registration_email/'+data.formInput.id_email,
                { headers }
            ).then( res => {
                if(res.status.toString().startsWith('20')){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    checkEmail({commit, payload}, email) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/document/registration_email?email='+ email
        return (!token)? false : apiBase
        .get(
            url,
            { headers }
        ).then( res => {
            return (res.status.toString().startsWith('20')) ? res.data : null
        })
    }
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
