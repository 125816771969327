import { apiBase } from '@/helpers'

const state = {
    uploadProgressBtn: 'Memproses ... (0%)'
}

const actions = {
    getCategoryTicketList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/category?'

        if(params.ticketType) url += 'category_ticket_type='+params.ticketType
        
        url += '&limit=10000'
        url += '&offset=0'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    getTicketList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket?'

        url += 'order_by=created_at'

        
        if(params.pagination){
            url += '&limit='+params.pagination.limit
            url += '&offset='+params.pagination.offset
        }       

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    

    getCustomTicketList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket?'
        
        if(params.filter) {
            let filter = params.filter


            if(filter.title != "") url += '&title=' + filter.title
            if(filter.selectedTicketCategory != null) {
                url += '&category_name=' + filter.selectedTicketCategory.category_name
            }    
            if(!filter.withoutPriority) {
                if(!filter.firstTimeLoad) {
                    url += '&priority=' + filter.priority
                }
            }

            if(filter.status)           url += '&status=' + filter.status
            if(filter.selectedAdmin)    url += '&admin_user_id=' + filter.selectedAdmin.user_id
            
            if(filter.selectedPIC)      url += '&pic=' + filter.selectedPIC
            if(filter.selectedTicketType)  url += '&ticket_type=' + filter.selectedTicketType.value
            if(filter.selectedSortBy) {
                url += '&order_by=' + filter.selectedSortBy.order_by
                url += '&sort=' + filter.selectedSortBy.sortBy
            }
        }
       
        if(params.pagination){
            url += '&limit='+params.pagination.limit
            url += '&offset='+params.pagination.offset
        }       

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    
    getTicketDoneCount({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket?'

        url += 'status=done'
    
        url += '&limit=1000'
        url += '&offset=0'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    uploadAttachment({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/attachment'

        let form = new FormData()
        let fileData = data.fileData
        let fileType = data.typeData
        form.append('file', fileData)
        form.append('file_type', fileType)

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })

    },

    createTicket({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket'

        let form = new FormData()
        let ticketData = data.ticketData
        form.append('title', ticketData.title)
        form.append('description', ticketData.description)
        if (ticketData.category_id && ticketData.category_id != '') form.append('category_id', ticketData.category_id)
        if (ticketData.file_id.length > 0 ) {
            form.append('file_id', ticketData.file_id)
        }

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },

    downloadAttachment({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded',
        }
        let fileData = data.fileData
        let url = 'v1/ticket/attachment/' + fileData.file_id

        return (!token)? false : apiBase
            .get(
                url,
                { 
                    headers,
                    responseType:'blob'
                }
            ).then( res => {
                let result = null
                var filename = ""
                var disposition = res.headers["content-disposition"]
                if (disposition) {
                    var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
                    var matches = filenameRegex.exec(disposition)
                    if (matches != null && matches[1]) { 
                        filename = matches[1].replace(/['"]/g,'')
                    }
                }

                result = {
                    filename: filename,
                    file: res.data
                }
                
                return (res.status.toString().startsWith('20'))? result : null
            }).catch( err => {
                console.log(err.response)
                return null
        })
    },

    addFileForExistingTicket({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        let ticketData = data.ticketData
        let url = 'v1/ticket/'+ticketData.ticket_id
        form.append('file_id', ticketData.file_id)

        return (!token)? false : apiBase
            .put(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },

    uploadSurveyTicket({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let form = new FormData()
        let ticketData = data.ticketData
        let url = 'v1/ticket/survey/'+ticketData.ticket_id
        form.append('rating', ticketData.rating)
        if (ticketData.catatan_rating && ticketData.catatan_rating != '') form.append('catatan_rating', ticketData.catatan_rating)

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },

    getTicketChatList({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/comment?'

        url += 'ticket_id=' + params.filter.ticketId
        url += '&limit=10000'
        url += '&offset=0'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },

    postTicketComment({commit, payload}, data) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/ticket/comment'

        let form = new FormData()
        let ticketData = data.formInput
        form.append('ticket_id', ticketData.ticketId)
        form.append('message', ticketData.message)

        return (!token)? false : apiBase
            .post(url, form, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
        })
    },
}


const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses ... (' + payload + ')'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
