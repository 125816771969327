import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: -1
}

const actions = {
    getUser({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user?'

        // Filters
        if (data.filter) {
            if(data.filter.username) url += '&username=' + data.filter.username
            if(data.filter.isActive) url += '&is_active=' + data.filter.isActive
            if(data.filter.satkerId) url += '&satker_id=' + data.filter.satkerId
            if(data.filter.search) url += '&search=' + data.filter.search
            if(data.filter.satkerLimit) url += '&satker_limit=' + data.filter.satkerLimit
        }

        if (data.order) {
            if(data.order.orderBy) url += '&order_by=' + data.order.orderBy
            if(data.order.orderType) url += '&order_type=' + data.order.orderType
        }

        if (data.pagination) {
            if(data.pagination.limit) url += '&limit=' + data.pagination.limit
            if(data.pagination.offset) url += '&offset=' + data.pagination.offset
        }
        

        return (!token)? false : apiBase
        .get(
            url, {headers}
        ).then( res => {
            if(res.status == 200 || res.status == 204){
                return res.data
            }else{
                return false
            }
        })
    },
    addUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.username && data.formInput.username != '') form.append('username', data.formInput.username)
        if(data.formInput.fullname && data.formInput.fullname != '') form.append('fullname', data.formInput.fullname)
        if(data.formInput.email && data.formInput.email != '') form.append('email', data.formInput.email)
        if(data.formInput.rank && data.formInput.rank != '') form.append('rank', data.formInput.rank)
        if(data.formInput.position && data.formInput.position != '') form.append('position', data.formInput.position)
        if(data.formInput.groups && data.formInput.groups.length > 0) form.append('groups', data.formInput.groups)
        if(data.formInput.password && data.formInput.password != '') form.append('password', data.formInput.password)
        
        return (!token)? false : apiBase
            .post(
                'v1/user',
                form,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    editUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.username && data.formInput.username != '') form.append('username', data.formInput.username)
        if(data.formInput.fullname && data.formInput.fullname != '') form.append('fullname', data.formInput.fullname)
        if(data.formInput.email && data.formInput.email != '') form.append('email', data.formInput.email)
        if(data.formInput.rank && data.formInput.rank != '') form.append('rank', data.formInput.rank)
        if(data.formInput.position && data.formInput.position != '') form.append('position', data.formInput.position)
        if(data.formInput.groups && data.formInput.groups.length > 0) form.append('groups', data.formInput.groups)
        if(data.formInput.nik && data.formInput.nik.length > 0) form.append('nik', data.formInput.nik)
        
        return (!token)? false : apiBase
            .put(
                'v1/user/'+data.formInput.userId+'',
                form,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    editPasswordUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.newPassword && data.formInput.newPassword != '') form.append('password', data.formInput.newPassword)
        
        return (!token)? false : apiBase
            .put(
                'v1/user/'+data.formInput.userId+'?',
                form,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return res.data
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    editPhotoUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.file && data.formInput.file.length > 0) form.append('file', data.formInput.file[0])
        console.log(data.formInput.file[0])
        
        return (!token)? false : apiBase
            .post(
                'v1/user/profile_picture',
                form,
                { 
                    headers,
                    onUploadProgress: uploadEvent => {
                        let progress = Math.round(uploadEvent.loaded / uploadEvent.total * 100)
                        commit('PROGRESS', progress)
                    }
                }
            ).then( res => {
                if(res.status == 200 || res.status == 201){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    deleteUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        return (!token)? false : apiBase
            .delete(
                'v1/user/'+data.formInput.userId+'', 
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    getUserRole({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/role/?'

        // Filters
        if(data.order){
            if(data.order.orderType) url += '&order_type='+data.order.orderType
        }

        if(data.pagination){
            url += '&limit='+data.pagination.limit
            url += '&offset='+data.pagination.offset
        }

        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return res.data
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    addUserRole({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        form.append('role_name', data.formInput.roleName)
        
        return (!token)? false : apiBase
            .post(
                'v1/role/',
                form,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    deleteUserRole({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        return (!token)? false : apiBase
            .delete(
                'v1/role/'+data.formInput.idRole+'',
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    addSignature({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        if(data.formInput.file && data.formInput.file != '') form.append('file', data.formInput.file)
        
        return (!token)? false : apiBase
            .post(
                'v1/user/signature',
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },


    getPassPhrase({commit,payload}, params ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/user/passphrase?user_id='+params.userId
        return (!token)? false : apiBase
            .get(
                url,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },

    
    savePassphrase({commit,payload}, params ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        let url = 'v1/user/passphrase'
        let form = new FormData()
        form.append('passphrase', params.passphrase)
        if(params.docId) {
            form.append('doc_id', params.docId)
        }
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return false
            })
    },

    getUserSpri({commit,payload}, data){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user/spri?user_id=' + data.user_id


        return (!token)? false : apiBase
            .get(
                url, {headers}
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return res.data
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },

    putSpriAccess({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }

        // Filters
        let form = new FormData()
        form.append('link_user', data.formInput.linkUser)
        form.append('is_link_user_active', data.formInput.is_link_user_active)

        return (!token)? false : apiBase
            .put(
                'v1/user/spri/'+data.userId+'',
                form,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 204){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
    
    deletePhotoUser({commit,payload}, data ){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        
        return (!token)? false : apiBase
            .delete(
                'v1/user/profile_picture/' + data.userId,
                { headers }
            ).then( res => {
                if(res.status == 200 || res.status == 201){
                    return true
                }else{
                    return false
                }
            }).catch( err => {
                return false
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = payload
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}