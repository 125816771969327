import { apiBase } from "@/helpers"
import qs from 'qs'

const state = {
	statusLog : false,
	showLogin : true,
    token : '',
    username : '',
    id_usergroup : 0,
    fullname : '',
    email : '',
    groups : [],
    rank : '',
    position : '',
    satkerId : '',
    satkerName : '',
    profile_picture_thumb_base64: '',
    user_id: '',
    passwordStore: '',
    isBsre: false
}

const actions = {
    async login({commit, payload}, data ){
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let formInput = qs.stringify({
            username: data.username,
            password: data.password
        })

        return apiBase
            .post(
                'v2/user/login',
                formInput,
                { headers }
            ).then(res => {
                if(res.data.is_blocked == false) {
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('user_id', res.data.user.user_id)
                    if(res.data.user.groups.length > 0) {
                        if(res.data.user.groups[0].group_id == 1) {
                            localStorage.setItem('isAdmin', true)
                        }else {
                            localStorage.setItem('isAdmin', false)
                        }
                    } else {
                        localStorage.setItem('isAdmin', false)
                    }
                    commit('LOGGED', res.data)
                }

                return res
            }).catch(err => {
                console.log(err)
                return false
            })
    },
    async loginV1({commit, payload}, data ){
        let headers = {
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let formInput = qs.stringify({
            username: data.username,
            password: data.password
        })

        return apiBase
            .post(
                'v1/user/login',
                formInput,
                { headers }
            ).then(res => {
                if(res.data.is_blocked == false) {
                    localStorage.setItem('token', res.data.token)
                    localStorage.setItem('user_id', res.data.user.user_id)
                    commit('LOGGED', res.data)
                }

                return res
            }).catch(err => {
                console.log(err)
                return false
            })
    },
    async getUserProfile({commit, payload}){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let userId = localStorage.getItem('user_id')
        if (
            (token != null && token != "undefined") && 
            (userId != null && userId != "undefined")
        ) {
            return apiBase
                .get(
                    'v1/user/'+ userId,
                    { headers }
                ).then( res => {
                    let data = {
                        token: token,
                        user: res.data.result
                    }
                    
                    commit('LOGGED', data)
                    return data
                }).catch( err => {
                    console.log(err)
                })
        }
    },
    async checkLogin({commit, payload}){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let userId = localStorage.getItem('user_id')
        if (
            (token != null && token != "undefined") && 
            (userId != null && userId != "undefined")
        ) {
            return apiBase
                .get('v1/user/'+ userId, { headers })
                .then(res => {
                    let data = {
                        token: token,
                        user: res.data.result
                    }
                    commit('LOGGED', data)

                    return true
                }).catch(err => {
                    console.log(err)
                    return false
                })
        } else {
            localStorage.removeItem('token')
            localStorage.removeItem('username')
            commit('LOGOUT')
            return false
        }
    },
    async logout({commit, payload}){
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user/logout'

            // Filters
            
            // Filters
            let form = new FormData()
            form.append('user_app_id', null)
            return apiBase
                .post(
                    url,
                    form,
                    {headers}
                ).then( res => {

                        localStorage.removeItem('token')
                        localStorage.removeItem('username')
                        commit('LOGOUT')
                        return true
                    
                }).catch( err => {

                    localStorage.removeItem('token')
                    localStorage.removeItem('username')
                    commit('LOGOUT')
                    return true
                })
    },
    async forgotPassword({commit, payload}, data ){
        let dat = qs.stringify({
            username: data.username,
            email: data.email
        })

        return apiBase
            .put('v1/user/forget_password', dat)
            .then(res => {
                console.log(res)
                return true
            }).catch( err => {
                console.log(err)
                if(err.response){
                    return false
                }else{
                    return false
                }
            })
    },
}

const mutations = {
    LOGGED(state, payload) {
        state.showLogin = false
        state.statusLog = true
        state.token = payload.token
        state.user_id = payload.user.user_id
        state.username = payload.user.username
        state.fullname = payload.user.fullname
        state.email = payload.user.email
        state.groups = payload.user.groups
        state.rank = payload.user.rank
        state.position_id = payload.user.position_id
        state.position = payload.user.position
        state.satker_id = payload.user.satker_id
        state.satker = (payload.user.satker)? payload.user.satker:"-"
        state.profile_picture_thumb_base64 = (payload.user.profile_picture_thumb_base64)? "data:image/jpeg;base64,"+payload.user.profile_picture_thumb_base64:''
        state.isBsre = (payload.user.is_bsre == true)?true:false
        state.nik = payload.user.nik
    },
    USER_ID(state, payload) {
        state.user_id = payload.user_id
    },
    LOGOUT(state) {
        state.showLogin = true
        state.statusLog = false
        state.token = ''
        state.notifCount = 0
        state.user_id = ''
        state.username = ''
        state.fullname = ''
        state.email = ''
        state.groups = ''
        state.rank = ''
        state.position_id = ''
        state.position = ''
        state.satker_id = ''
        state.satker = ''
        state.passwordStore = ''
        state.profile_picture_thumb_base64 = ''
        state.isBsre = false
        state.nik = ''
    }    
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}