import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/satker'

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    }
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}