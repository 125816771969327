<template>
<div>
	<vue-page-transition name="fade">
		<Index/>
	</vue-page-transition>
</div>
</template>

<script>
import Index from '@/views/Index';

export default {
	name: 'App',
	components: {
		Index
	}
}
</script>

<style lang="scss">
	@import 'assets/scss/style';
</style>