<template>
<div>
    <Container v-if="!showLogin && statusLog"/>
    <ContainerAuth v-else/>
    <vue-confirm-dialog></vue-confirm-dialog>
</div>
</template>

<script>
import Container from '@/components/layout/Container.vue'
import ContainerAuth from '@/components/layout/ContainerAuth.vue'
import { mapState } from 'vuex'

export default {
    names: 'Index',
    components: {
        Container,
        ContainerAuth
    },
    mounted() {
        this.checkLog()
    },
    computed: {
        ...mapState('auth', {
            token: 'token',
            fullname: 'fullname',
            position: 'position',
            statusLog: 'statusLog',
            showLogin: 'showLogin',
            notifCount: 'notifCount'
        }),
    },
    methods: {
        async checkLog () {
            await this.$store.dispatch(
                {
                    type: 'auth/checkLogin'
                }
            ).then((res) => {
                if (res) {
                    this.$toasted.success('Selamat Datang ' + (this.position? this.position:this.fullname)).goAway(3000)

                    this.$store.dispatch('auth/getUserProfile')
                    if(
                        this.$route.path == '/login' ||
                        this.$route.path == '/forgot_password' 
                    ){
                        this.redir('/beranda')
                    }
                }else{
                    var routeName = window.location.pathname
                    if( routeName.includes('download_page') || 
                        routeName.includes('bsre') || 
                        routeName.includes('book')){
                        console.log('ok')
                    } else if(
                        this.$route.path != '/forgot_password' || this.$route.path != '/download_page'
                    ){
                        this.redir('/login')
                    }
                }
            })
        },
        redir (message) {
			if(this.$route.path != message){
				this.$router.push(message)
			}
		}
	}
}
</script>