import { apiBase } from "@/helpers"

const state = { 
    uploadProgressBtn: 'Memproses... (0%)'
}

const actions = {
    // Position Config
    get({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/user_position?'

        // Filters
        if (params.filter) {
            console.log(params.filter.position)
            if (params.filter.position) url += '&position='+params.filter.position
            if (params.filter.config) url += '&config='+params.filter.config
            if (params.filter.isRoot !== undefined) url += '&is_exact='+params.filter.isExact
            if (params.filter.isExact !== undefined) url += '&is_exact='+params.filter.isExact
        }

        if (params.order) {
            if (params.order.orderBy) url += '&order_by'+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    store({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user_position'

        let form = new FormData()
        if (params.formInput.position && params.formInput.position != '') form.append('position', params.formInput.position)
        if (params.formInput.config && params.formInput.config != '') form.append('config', params.formInput.config)
        
        return (!token)? false : apiBase
            .post(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status == 200 || res.status == 204) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    fetch({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/user_position/' + params.positionId

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : false
            })
    },
    update({commit,payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user_position/'+ params.formInput.configId

        let form = new FormData()
        if (params.formInput.config && params.formInput.config != '') form.append('config', params.formInput.config)
        
        return (!token)? false : apiBase
            .put(
                url,
                form,
                { headers }
            ).then( res => {
                return (res.status == 200 || res.status == 204) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    destroy({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v1/user_position/'+ params.formInput.configId
        
        return (!token)? false : apiBase
            .delete(
                url,
                { headers }
            ).then( res => {
                return (res.status == 200 || res.status == 204) ? true : false
            }).catch( err => {
                return false
            })
    },

    // User Position Config
    getAllPosition({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/user_position/show_all_position?'

        // FIlters
        if (params.filter)
            if (params.filter.choice) url += '&choice='+ params.filter.choice

        return (!token) ? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status == 200 || res.status == 204) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    getUserConfig({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/user_position/user_position_config?'

        // Filters
        if (params.filter) {
            if (params.filter.satkerId) url += '&satker_id='+ params.filter.satkerId
            if (params.filter.satker) url += '&satker='+ params.filter.satker
            if (params.filter.username) url += '&username='+ params.filter.username
            if (params.filter.position) url += '&position='+ params.filter.position
            if (params.filter.isExact !== undefined) url += '&is_exact='+ params.filter.isExact
        }

        if (params.order) {
            if (params.order.orderBy) url += '&order_by='+ params.order.orderBy
            if (params.order.orderType) url += '&order_type='+ params.order.orderType
        }

        if (params.pagination) {
            if (params.pagination.limit != null) url += '&limit='+ params.pagination.limit
            if (params.pagination.offset != null) url += '&offset='+ params.pagination.offset
        }

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return null
            })
    },
    getUserPositionPid({commit, payload}, params) {
        let token = localStorage.getItem('token')
        let headers = {
            'Authorization': 'Bearer '+ token,
            'Content-Type': 'application/x-www-form-urlencoded'
        }
        let url = 'v2/user_position/position_pid/'+ params.filter.positionPid

        return (!token)? false : apiBase
            .get(url, { headers })
            .then( res => {
                return (res.status.toString().startsWith('20')) ? res.data : null
            }).catch( err => {
                return null
            })
    },
}

const mutations = {
    PROGRESS(state, payload){
        state.uploadProgressBtn = 'Memproses... ('+payload+'%)'
    }
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}